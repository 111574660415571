import { ContentType, request } from "../api";
import { baseParams } from "../common";

const searchResult = result => {
  const { supplier } = result.included;
  let newResult = { ...result };

  newResult.flights = result?.flights.map(recommendation => {
    const supplierData = supplier[recommendation["validating_supplier"]];
    const newRecommendation = { ...recommendation };
    newRecommendation["lowcost"] = supplierData?.lowcost || false;
    newRecommendation.routes = formattedRoutesData(recommendation.routes);
    return newRecommendation;
  });

  return newResult;
};

export const formattedRoutesData = routes =>
  routes.map(route => {
    const newRoute = { ...route };

    newRoute.segments = newRoute.segments.map(segment => {
      const newSegment = { ...segment };

      switch (newSegment["baggage"]) {
        case newSegment["baggage"] === null:
        case "0":
        case "0N":
        case "0 PC":
        case "0PC":
        case "N/A":
        case "NO":
        case "0 N/A":
          newSegment["baggage"] = 0;
          break;
        case "1":
        case "1P":
        case "1 PC":
        case "10K":
        case "10 KG":
        case "20 KG":
        case "23 KG":
        case "25 KG":
        case "30 KG":
        case "35 KG":
        case "40 KG":
        case "1PC":
          newSegment["baggage"] = 1;
          break;
        case "2P":
        case "2 PC":
          newSegment["baggage"] = 2;
          break;
        default:
          break;
      }

      switch (newSegment["hand_luggage"]) {
        case newSegment["hand_luggage"] === null:
        case "0":
        case "0 PC":
        case "0PC":
        case "N/A":
        case "0 N/A":
          newSegment["hand_luggage"] = 0;
          break;
        case "1":
        case "1P":
        case "1 PC":
        case "10K":
        case "5 KG":
        case "6 KG":
        case "7 KG":
        case "8 KG":
        case "10 KG":
        case "15 KG":
        case "1PC":
          newSegment["hand_luggage"] = 1;
          break;
        case "2":
        case "2P":
          newSegment["hand_luggage"] = 2;
          break;
        default:
          break;
      }

      if (typeof newSegment["baggage_weight"] !== "number") {
        switch (newSegment["baggage_weight"]) {
          case newSegment["baggage_weight"] === null:
          case "0 N/A":
          case "N/A":
            newSegment["baggage_weight"] = 0;
            break;
          case "10 KG":
            newSegment["baggage_weight"] = 10;
            break;
          case "20 KG":
            newSegment["baggage_weight"] = 20;
            break;
          case "23 KG":
            newSegment["baggage_weight"] = 23;
            break;
          case "25 KG":
            newSegment["baggage_weight"] = 25;
            break;
          case "30 KG":
            newSegment["baggage_weight"] = 30;
            break;
          case "35 KG":
            newSegment["baggage_weight"] = 35;
            break;
          case "40 KG":
            newSegment["baggage_weight"] = 40;
            break;
          default:
            newSegment["baggage_weight"] = 0;
        }
      }

      if (typeof newSegment["hand_luggage_weight"] !== "number") {        
        switch (newSegment["hand_luggage_weight"]) {
          case typeof newSegment["hand_luggage_weight"] === "number": break;
          case newSegment["hand_luggage_weight"] === null:
          case "0 N/A":
          case "1 PC":
          case "N/A":
            newSegment["hand_luggage_weight"] = 0;
            break;
          case "5 KG":
            newSegment["hand_luggage_weight"] = 5;
            break;
          case "6 KG":
            newSegment["hand_luggage_weight"] = 6;
            break;
          case "7 KG":
            newSegment["hand_luggage_weight"] = 7;
            break;
          case "8 KG":
            newSegment["hand_luggage_weight"] = 8;
            break;
          case "10 KG":
            newSegment["hand_luggage_weight"] = 10;
            break;
          case "15 KG":
            newSegment["hand_luggage_weight"] = 15;
            break;
          default:
            newSegment["hand_luggage_weight"] = 0;
        }
      }

      return newSegment;
    });

    return newRoute;
  });

export const searchFlight = async (data = {}, language, params = {}) => {
  const body = {
    ...baseParams,
    language,
    cabin: data.cabin,
    flight_type: data.flightType,
    passengers: data.passengers,
    routes: data.routes,
  };

  const response = await request({
    path: "/search",
    method: "POST",
    body,
    type: ContentType.Json,
    format: "json",
    ...params,
  });

  if (response.data?.error_key) return response.data;

  return searchResult(response.data);
};

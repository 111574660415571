import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

/* Здесь использован относительный путь так как плагин babel для импорта файлов не работает с абсолютным путем */
// eslint-disable-next-line import/no-unresolved, import/extensions
import translations from "./translations";

/* кофиг для i18n */
const initI18n = {
  fallbackLng: ["uz", "en", "ru", "tj"],
  resources: translations,
  detection: {
    order: ["querystring", "localStorage"], // определяет язык по: строке запроса(lang=ru), по браузеру, по LS.
    lookupQuerystring: "lang", // в строке запроса ищет квери параметр lang.
  },
  supportedLngs: ["uz", "en", "ru", "tj"], // поддержка языков, как название папок в директории translation (en, ru).
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  debug: true,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(initI18n);

import { City, Date, LinkItem, PublicOfferContent, PublicOfferContentLink, PublicOfferContentLinkComponent, PublicOfferDate, PublicOfferInfo, PublicOfferInfoTitle, PublicOfferTitle, Wrapper, } from "./style";

export const PublicOffer = () => {
	return (
			<Wrapper>
				<PublicOfferTitle>
					ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ ОБ ОКАЗАНИИ УСЛУГ
				</PublicOfferTitle>

				<PublicOfferDate>
					<City>
						г. Ташкент
					</City>
					<Date>
						15.02.2024 г.
					</Date>
				</PublicOfferDate>

				<PublicOfferInfo>
					<PublicOfferContent>
						Настоящая публичная оферта ООО «AMMY TRAVEL» (далее по тексту «Исполнитель»), адресована любому физическому или юридическому дееспособному лицу, (далее по тексту «Клиент») на оказание услуги по онлайн бронированию услуг, описанных в п. 1.5. настоящей оферты по принципу «в одно окно» (далее по тексту «Услуги»), на изложенных в настоящем Соглашении условиях.
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						1. Определения терминов, используемых в соглашении
					</PublicOfferInfoTitle>
					<PublicOfferContent>
						1.1. Онлайн-платформа – «SaaS» версия программы для онлайн бронирования услуг, указанных в п. 2.1. настоящей оферты по принципу «в одно окно», размещенная на арендованном сервере у Провайдера услуг. «SaaS» версия – версия программы, которая предоставляется без скачивания программы, на интернет-сайте <a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me.</a>
					</PublicOfferContent>

					<PublicOfferContent>
						1.2. Клиент – любое дееспособное физическое или юридическое лицо, приобретающее услуги Исполнителя для себя или в пользу третьего лица посредством онлайн-платформы.
					</PublicOfferContent>

					<PublicOfferContent>
						1.3. Поставщики услуг – юридические лица, непосредственно оказывающие услуги по: перевозке пассажиров, страхованию, аренде автомобилей, бронированию гостиничных номеров.
					</PublicOfferContent>

					<PublicOfferContent>
						1.4. Услуги – Услуги по бронированию номеров в объектах размещения, страхование, бронирование и продажа авиа и железнодорожных билетов, аренда автомобилей.
					</PublicOfferContent>

					<PublicOfferContent>
						1.5. Персональные данные – любая информация, которую Клиент предоставляет о себе и третьих лицах, самостоятельно при регистрации на Сайте, а также в процессе дальнейшего использования Сайта. К такой информации, в частности, следует относить: фамилию, имя, отчество, год, месяц, дата и место рождения, адрес, email, номер мобильного телефона, а также другую информацию, включая ряд технических данных: IP адрес, историю посещений браузера и другие.
					</PublicOfferContent>

					<PublicOfferContent>
						1.6. Обработка персональных данных – реализация одного или совокупности действий по сбору, систематизации, хранению, изменению, дополнению, использованию, предоставлению, распространению, передаче, обезличиванию и уничтожению персональных данных, совершаемых с внедрением средств автоматизации или же без применения таких средств.
					</PublicOfferContent>

					<PublicOfferContent>
						1.7. Личный кабинет — раздел сайта, с помощью которого Клиент может реализовать функционал онлайн-платформы, доступный только после прохождения регистрации и/или авторизации на сайте путем ввода уникальной учетной информации (комбинации логина и пароля). Личный кабинет предназначен для хранения персональных данных Клиента (личные данные и документы), оформления Заказов, просмотра информации о совершенных Заказах, стадии их выполнения, текущее состояние выставленных счетов, и получения уведомлений.
					</PublicOfferContent>

					<PublicOfferContent>
						1.8. Заказ – надлежаще оформленная Клиентом Заявка на получение услуг, выбранных посредством онлайн-платформы.
					</PublicOfferContent>

					<PublicOfferContent>
						1.9. Сервисный сбор – оплата за дополнительные услуги Исполнителя и/или Поставщика Услуг.
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						2. Предмет соглашения
					</PublicOfferInfoTitle>
					<PublicOfferContent>
						2.1. Предметом настоящей оферты является предоставление Клиенту услуг по онлайн бронированию Услуг по принципу «в одно окно» на условиях, предусмотренных настоящей офертой, а именно:
					</PublicOfferContent>

					<PublicOfferContentLink>
						<PublicOfferContentLinkComponent>
							поиск и бронирование авиабилетов;
						</PublicOfferContentLinkComponent>
					</PublicOfferContentLink>

					<PublicOfferContent>
						2.2. Безусловным акцептом условий настоящей публичной оферты является частичная или полная оплата услуг по квитанции, сформированной Клиентом на сайте <a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me</a>  путем активации раздела «Оплата», с проставлением отметки на пункте: «Я принимаю условия Публичной Оферты, Пользовательского соглашения и согласен на обработку моих персональных данных». Акцепт настоящей оферты на иных условиях, отличных от тех, что указаны в настоящей оферте, либо акцепт под условием, не допускается.
					</PublicOfferContent>

					<PublicOfferContent>
						2.3. Исключительное право на онлайн-платформу <a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me</a> принадлежит ООО «AMMY TRAVEL».
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						3. Условия и порядок оказания услуг
					</PublicOfferInfoTitle>

					<PublicOfferContent>
						3.1. Клиент для получения услуги Исполнителя через онлайн-платформу, проходит регистрацию на интернет-сайте <a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me.</a> Регистрация Клиента в системе <a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me</a> осуществляется путем автоматического открытия аккаунта после оформления первого заказа.
					</PublicOfferContent>

					<PublicOfferContent>
						3.2. Клиент самостоятельно подает Заявку на бронирование Услуг Исполнителя в следующем порядке:
					</PublicOfferContent>

					<PublicOfferContent>
						3.2.1. Порядок бронирования услуг осуществляется в следующем порядке:
					</PublicOfferContent>

					<PublicOfferContentLink>
						<PublicOfferContentLinkComponent>
							бронирование авиа билетов не позднее, чем за 6 (три) часа до отправления самолета;
						</PublicOfferContentLinkComponent>
						<PublicOfferContentLinkComponent>
							бронирование остальных Услуг, перечисленных в пункте 2.1. настоящей оферты не позднее, чем за 2 (два) часа до начала их оказания.
						</PublicOfferContentLinkComponent>
					</PublicOfferContentLink>

					<PublicOfferContent>
						3.3. Порядок редактирования и аннулирования Заявок. Штрафные санкции.
					</PublicOfferContent>

					<PublicOfferContent>
						3.3.1. Аннулирование/редактирование ранее оформленных/оплаченных Услуг, производится Клиентом через систему «<a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me</a>» путём самостоятельного, без привлечения персонального менеджера, внесения соответствующих изменений в Заявку.
					</PublicOfferContent>

					<PublicOfferContent>
						3.3.2. Аннулирование/редактирование ранее оформленных/оплаченных Услуг, производится Клиентом через систему «<a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me</a>» путём самостоятельного, без привлечения персонального менеджера, внесения соответствующих изменений в Заявку.
					</PublicOfferContent>

					<PublicOfferContent>
						3.3.3. Денежные средства, оставшиеся после всех удержаний/аннулирования/редактирования, по выбору Клиента возвращаются Клиенту на его счёт в течение 5 (пяти) банковских дней со дня получения требования от Клиента или учитываются в качестве предоплаты по будущим Заявкам.
					</PublicOfferContent>

					<PublicOfferContent>
						3.4. Заказы, оформленные Клиентом на Сайте «<a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me</a>», носят окончательный характер и подлежат автоматизированной обработке в системе обработки данных, вследствие чего на адрес электронной почты Клиента, направляются письма со статусом Заказа. В случае если Заказ был оформлен с ошибками, допущенными Клиентом при заполнении формы бронирования на Сайте, Исполнитель и Поставщик услуг вправе отказаться от внесения изменений в Заказ, и не будут нести ответственности за неправильно оформленный Клиентом Заказ. При этом денежные средства Клиенту будут возвращены в соответствии с правилами Поставщика Услуг.
					</PublicOfferContent>

					<PublicOfferContent>
						3.5. Исполнитель не несет ответственности за фактическое исполнение/неисполнение обязательств Поставщиком услуг перед Клиентами.
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						4. Стоимость услуг и порядок расчетов
					</PublicOfferInfoTitle>

					<PublicOfferContent>
						4.1. Стоимость Услуг указывается в системе «<a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me</a>» в национальной валюте - сум непосредственно самим Поставщиком, либо Исполнителем, согласно Договору с Поставщиками. Факт подачи Заявки через систему «<a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me</a>» является согласием Клиента со стоимостью Услуг Поставщика и правилами оказания услуг Поставщиком.
					</PublicOfferContent>

					<PublicOfferContent>
						4.2. 100% оплата стоимости Услуг, указанных в Заявке, производится в момент оформления Заявки через систему «<a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me.</a>»
					</PublicOfferContent>

					<PublicOfferContent>
						4.3. Оплата производится Клиентом в национальной валюте - сум. Все расчеты по настоящему Договору могут производиться посредством платежных систем, а также путем перечисления денежных средств на расчетный счёт Исполнителя. Оплата, за услуги осуществляемая нерезидентами может производиться в свободно конвертируемой валюте кредитными/дебетовыми картами, а также путем перечисления денежных средств на валютный счет Исполнителя на основании экспортного контракта.
					</PublicOfferContent>

					<PublicOfferContent>
						4.4. Обязательство по оплате Услуг Исполнителя считается выполненным в момент зачисления на банковский счет Исполнителя денежных средств в размере, рассчитанном согласно его тарифам и зафиксированном в системе «<a href="https://biletbor.me" target="_blank" rel="noreferrer">https://biletbor.me.</a>» Расходы за проведение платежей, связанных с переводом валютных средств, в том числе комиссии банка-корреспондента, несет Клиент.
					</PublicOfferContent>

					<PublicOfferContent>
						4.5. Исполнитель имеет право взимать с Клиента сервисный сбор за свои услуги и услуги Поставщика услуг. Сервисный сбор формируется и оплачивается Клиентом в момент оплаты Услуг.
					</PublicOfferContent>

					<PublicOfferContent>
						4.6. Исполнитель не контролирует аппаратно-программный комплекс систем платежей и не несет ответственности за ошибки в таком аппаратно-техническом комплексе. Если в результате таких ошибок произошло списание денежных средств Клиента, но платеж не был авторизован системой, обязанности по возврату денежных средств Клиенту лежат на провайдере электронной системы платежей.
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						5. Ответственность сторон
					</PublicOfferInfoTitle>

					<PublicOfferContent>
						5.1. За неисполнение или ненадлежащее исполнение обязательств по настоящему Договору, Стороны несут ответственность в соответствии с действующим законодательством РУз.
					</PublicOfferContent>

					<PublicOfferContent>
						5.2. Исполнитель не несет ответственности:
					</PublicOfferContent>

					<PublicOfferContentLink>
						<PublicOfferContentLinkComponent>
							в случае невозможности выполнения принятых на себя обязательств, вследствие недостоверности, недостаточности и несвоевременности сведений и документов, предоставленных Клиентом или нарушения Клиентом условий настоящей оферты;
						</PublicOfferContentLinkComponent>
						<PublicOfferContentLinkComponent>
							за качество услуг, оказываемых Поставщиками услуг. При изменении, отмене, переносе, задержке и т.д. Поставщиком услуг, оплаченных услуг, Поставщик услуг несет ответственность перед Клиентом согласно установленных Поставщиком Правил и действующего законодательства РУз.
						</PublicOfferContentLinkComponent>
						<PublicOfferContentLinkComponent>
							за негативные последствия и убытки, возникшие в результате событий и обстоятельств, находящиеся вне сферы его компетенции, а также за действия/бездействия третьих лиц.
						</PublicOfferContentLinkComponent>
						<PublicOfferContentLinkComponent>
							за невозможность оформления Клиентом заказа по причинам нарушения работы линий связи, неисправность оборудования Клиента и т.п.
						</PublicOfferContentLinkComponent>
					</PublicOfferContentLink>

					<PublicOfferContent>
						5.3. В случае если Клиент имеет претензии, связанные с оказанием/неоказанием/ненадлежащим оказанием Услуг Поставщиком услуг, Клиент обращается за урегулированием споров к Поставщику услуг.
					</PublicOfferContent>

					<PublicOfferContent>
						5.4. Ни при каких обстоятельствах, ответственность Исполнителя не будет превышать сумму равную 2 БРВ РУз.
					</PublicOfferContent>

					<PublicOfferContent>
						5.5. Мера ответственности Сторон, не оговоренная условиями настоящего Договора, регулируется действующим законодательством РУз.
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						6. Порядок урегулирования споров (третейская оговорка)
					</PublicOfferInfoTitle>

					<PublicOfferContent>
						6.1. Все споры, которые могут возникнуть между сторонами по исполнению настоящего договора, либо связанные с договором другие споры, споры о недействительности настоящего договора и/или его третейской оговорки, подлежат рассмотрению в постоянно действующем третейском суде при ННО «Центре изучения проблем бизнеса и инвестиций» (100115. г.Ташкент, Чиланзарский р-н., ул.Арнасай, д.8, тел. (71) 277-59-70).
					</PublicOfferContent>

					<PublicOfferContent>
						6.2. Третейское разбирательство производится по правилам Регламента данного третейского суда, при участии судьи (судей), назначаемого (назначаемых) председателем третейского суда. Язык разбирательства – государственный язык, либо другой доступный для сторон язык. Место разбирательства – здание третейского суда.
					</PublicOfferContent>

					<PublicOfferContent>
						6.3. Претензионный порядок разрешения споров не применяется. Протокол заседания третейского суда не ведется.
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						7. Конфиденциальность и защита персональных данных
					</PublicOfferInfoTitle>

					<PublicOfferContent>
						7.1. Исполнитель гарантирует Клиенту принятие всех необходимых мер по обеспечению конфиденциальности персональных данных Клиента в соответствии с требованиями действующего законодательства Республики Узбекистан. Согласие Клиента на обработку (в том числе передачу третьим лицам) его персональных данных является бессрочным, при этом согласие на обработку персональных данных может быть отозвано Клиентом в любое время, путем отправки электронного письма по адресу: <LinkItem to="mailto:info@biletbor.me">info@biletbor.me</LinkItem>
					</PublicOfferContent>

					<PublicOfferContent>
						7.2. Принимая данное Соглашение, Клиент предоставляет Исполнителю, в соответствии с требованиями законодательства о персональных данных, бессрочное согласие на обработку своих персональных данных (в том числе, но не исключая: учетные данные, номер сотового телефона, ФИО, регистрационные данные, местоположение, а также любые другие персональные данные необходимые для использования онлайн-платформы), включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, совершаемых с использованием средств автоматизации или без использования таких средств, с целью исполнения настоящего Соглашения. Настоящим Клиент также дает согласие на передачу любых персональных данных, предоставленных им Исполнителю по настоящему Соглашению, третьим лицам в целях исполнения настоящего Соглашения.
					</PublicOfferContent>

					<PublicOfferContent>
						7.3. Исполнитель осуществляет обработку персональных данных в строгом соответствии с Законом РУз «О персональных данных».
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						8. Основания освобождения от ответственности (Форс-Мажор)
					</PublicOfferInfoTitle>

					<PublicOfferContent>
						8.1. Стороны не несут ответственности за задержки в исполнении или неисполнении своих обязанностей по настоящему Договору, если указанные задержки или неисполнение произошли вследствие обстоятельств непреодолимой силы; в число таких обстоятельств входят: война (включая гражданскую); мятежи, забастовки, пожары, взрывы, наводнения или иные стихийные бедствия, а также сбои в работе хостинговых компаний. Любое из перечисленных выше обстоятельств рассматривается как обстоятельство непреодолимой силы (форс-мажор). Немедленно после получения информации о наступлении обстоятельств, задерживающих исполнение или иным образом препятствующих исполнению настоящего Договора, Стороны письменно уведомляют об этом друг друга.
					</PublicOfferContent>

					<PublicOfferContent>
						8.2. Равным образом, обстоятельствами, освобождающими Стороны от ответственности за задержки в исполнении или неисполнении их обязанностей по настоящему Договору, является объявление эмбарго, иные действия или бездействие органов власти и управления Узбекистана или других стран, непосредственно влияющие на исполнение Сторонами их обязанностей.
					</PublicOfferContent>

					<PublicOfferContent>
						8.3. Требование любой Стороны о возмещении ущерба, вызванного обстоятельствами, указанными в п.п. 8.1 и 8.2 Договора, является ничтожным.
					</PublicOfferContent>

					<PublicOfferContent>
						8.4. Если обстоятельства, указанные в п.п. 8.1 и 8.2 Договора, продолжают действовать по истечении 30 (Тридцать) дней с момента их возникновения и это вызывает существенное нарушение прав и интересов любой Стороны, эта Сторона вправе уведомить в письменной форме другую Сторону о намерении прекратить Договор. Договор считается прекращенным по истечении 30 (тридцати) дней после направления указанного уведомления.
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						9. Срок действия договора
					</PublicOfferInfoTitle>

					<PublicOfferContent>
						9.1. Настоящий Договор вступает в силу с момента его акцепта Клиентом.
					</PublicOfferContent>

					<PublicOfferContent>
						9.2. По вопросам, связанным с исполнением Соглашения, необходимо обращаться по адресу местонахождения ООО «AMMY TRAVEL»: г. Самарканд,
					</PublicOfferContent>
				</PublicOfferInfo>

				<PublicOfferInfo>
					<PublicOfferInfoTitle>
						10. Юридические адреса и реквизиты Сторон
					</PublicOfferInfoTitle>

					<PublicOfferContent>
						10.1. Полное название: Общество с Ограниченной Ответственностью "AMMY TRAVEL"
					</PublicOfferContent>

					<PublicOfferContent>
						10.2. Сокращенное название: OOO "AMMY TRAVEL"
					</PublicOfferContent>

					<PublicOfferContent>
						10.3. Юридический адрес: 140100, Республика Узбекистан, Самаркандская обл., г. Самарканд, ул. A. Зомий, 35
					</PublicOfferContent>

					<PublicOfferContent>
						10.4. Электронная почта: <a href="mailto:ammytravelskd@mail.ru">ammytravelskd@mail.ru</a>, <a href="mailto:account@ammytravel.uz">account@ammytravel.uz</a>
					</PublicOfferContent>

					<PublicOfferContent>
						10.5. Тел.: +998 (66) 231-77-77 , +998 (66) 231-00-02
					</PublicOfferContent>

					<PublicOfferContent>
						10.6. OKED (Национальная классификация предприятий): 79110
					</PublicOfferContent>

					<PublicOfferContent>
						10.7. ИНН (Идентификационный номер налогоплательщика): 309 835 623
					</PublicOfferContent>

					<PublicOfferContent>
						10.8. Детали Банка:

						<PublicOfferContentLinkComponent>
							Расчетный счет: 2020 8000 1055 7055 8001
						</PublicOfferContentLinkComponent>

						<PublicOfferContentLinkComponent>
							Валютный Счет (USD): 2020 8840 4055 7055 8001
						</PublicOfferContentLinkComponent>

						<PublicOfferContentLinkComponent>
							"Kapitalbank" ОАО, Самаркандский Филиал
						</PublicOfferContentLinkComponent>

						<PublicOfferContentLinkComponent>
							МФО (Sort Code): 01061
						</PublicOfferContentLinkComponent>

						<PublicOfferContentLinkComponent>
							SWIFT: KACHUZ22
						</PublicOfferContentLinkComponent>
					</PublicOfferContent>

				</PublicOfferInfo>

				<PublicOfferContent>
					10.9. Директор: Тиллаев Джахонгир Абдувохидович
				</PublicOfferContent>
			</Wrapper>
	)
};
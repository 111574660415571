import styled from "styled-components";

import { ContainerComponent } from "../../ui";

export const Wrapper = styled("section")(({ theme, variant }) => ({
	height: variant === "full" ? 700 : "auto",
  backgroundImage: variant === "full" ? "url(/images/background-2.jpg)" : "" ,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  marginBottom: "50px",

	[theme.down("xl")]: {
    height: variant === "full" ? 500 : "auto",
  },

  [theme.down("sm")]: {
    height: "auto",
  },
}));

export const WrapperContainer = styled(ContainerComponent)(({ theme }) => ({
  height: "100%",
  position: "relative",

  [theme.down("sm")]: {
    ...theme.paddings(20, 0),
  },
}));

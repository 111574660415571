import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 15,
}));

export const Title = styled("div")(({ theme }) => ({
  fontSize: 24,
  fontWeight: "bold",
}));

import { useEffect, useRef, useState } from "react";

import { default as AuthContext } from "./context";
import { getUserInfo } from "../../protocols/rest/getUserInfo";
import { getToken } from "../../protocols/common";

const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const getUserRequest = useRef(async () => {
    const token = getToken();

    if (token) {
      try {
        const result = await getUserInfo();

        if (!result?.data?.error) {
          setUser(result?.data);
          setIsAuth(true);
        } else {
          localStorage.removeItem("token");
          setIsAuth(false);
        }
      } catch (error) {
        localStorage.removeItem("token");
        setIsAuth(false);
        console.log(error);
      } finally {
        setIsFirstLoading(false);
      }
    } else {
      setIsFirstLoading(false);
    }
  });

  useEffect(() => {
    getUserRequest.current();
  }, []);

  const getUserData = async (data = null) => {
    if (data) {
      setUser(data);
      setIsAuth(true);
    } else {
      getUserRequest.current();
    }
  };

  const handleChangeIsAuth = (value) => setIsAuth(value);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuth(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        user,
        getUserData,
        onChangeIsAuth: handleChangeIsAuth,
        onLogout: handleLogout,
      }}
    >
      {!isFirstLoading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

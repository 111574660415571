import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "370px",
  height: "100%",
  maxHeight: "230px",
  padding: "25px",

  backgroundColor: "#fff",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",

  position: "relative",
  overflow: "hidden",

  [theme.down("md")]: {
    maxWidth: "340px",
  },
}));

export const ImgCashback = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

export const CardDesc = styled("div")(({ theme }) => ({
  marginTop: "20px",
}));

export const Img = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "41px",

  "& img": {
    width: "100%",
    maxWidth: "180px",
    height: "auto",
  },
}));

export const Cashback = styled("div")(({ theme }) => ({
  padding: "3px 17px",
  position: "absolute",
  top: "20px",
  right: "-5px",
  zIndex: "1",

  backgroundColor: theme.palette.searchButtonBg,
  color: theme.palette.white,
  borderRadius: "5px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",

  ".transparent": {
    backgroundColor: "none",
    boxShadow: "none",
  },

  [theme.upDown("xs", "lg")]: {
    padding: "3px 13px",
    fontSize: "14px",
  },

  [theme.down("xs")]: {
    padding: "3px 13px",
    fontSize: "13px",
  },
}));

export const Title = styled("div")(({ theme }) => ({
  padding: "10px 0",
  fontSize: 13,

  [theme.down("sm")]: {
    fontSize: "10px",
  },
}));

export const Desc = styled("div")(({ theme }) => ({
  fontSize: "15px",
  fontWeight: "normal",
  lineHeight: "24px",

  [theme.down("sm")]: {
    fontSize: "13px",
  },
}));

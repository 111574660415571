import { useEffect } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Home, SeoTags } from "../../components";
import { setAppConfigs } from "../../store/appConfigsSlice";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppConfigs({ showSearch: true, searchBlockCompact: false }));
  }, []);

  return (
    <>
      <SeoTags pageKey="homePage" />
      <Home />
    </>
  );
};

export default withTranslation()(HomePage);

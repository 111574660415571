import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSearchBlockCompact } from "../../store/appConfigsSlice";

import { SeoTags } from "../../components";
import { PartnersInfoComponent } from "../../components/info/PartnersComponent/PartnersInfoComponent";

const PartnersInfoPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchBlockCompact(true));
  }, []);

  return (
    <>
      <SeoTags pageKey="PartnersPage" />
      <PartnersInfoComponent />
    </>
  );
};

export default withTranslation()(PartnersInfoPage);

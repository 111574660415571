export const data = [
  {
    name: "aeroflot",
    img_url: "/images/AK/Aeroflot_logo.svg",
    title: "aeroflot",
    description: [
      "С января по июнь Аэрофлот продолжает развивать маршрутную сеть, совершенствовать сервис и увеличивать уровень транспортной доступности регионов России.",
      "В январе-феврале авиакомпания завершила урегулирование в отношении ещё 7 воздушных судов с двумя группами лизингодателей.",
      "В марте Группа «Аэрофлот» перешла на летнее сезонное расписание, которое будет действовать по 26 октября 2024 года. В новом сезоне география полётов Аэрофлота и дочерних авиакомпаний «Победа» и «Россия» насчитывает 269 направлений, что на 7% больше, чем в летнем расписании 2023 года. Из них 149 — внутренние маршруты.",
      "В первом квартале 2024 года Аэрофлот увечил количество перевезённых пассажиров на собственных рейсах между Москвой и Сочи, Москвой и Минеральными Водами, Москвой и Махачкалой между Москвой и Уфой, Москвой и Челябинском. В летнем сезоне авиакомпания выполняет рекордное количество рейсом между Москвой и Сочи, Москвой и Казанью.",
      "В первом полугодии 2024 года авиакомпания предлагает новые услуги для пассажиров. В весной 2024 года впервые на борту в классе «Бизнес» появилось безлактозное молоко. Авиаперевозчик совместно с компанией СДЭК запустил новый сервис курьерской авиадоставки «АэрофлотСДЭК». После успешного тестирования вводится новая услуга перевозки животных на соседнем кресле. Аэрофлот совместно со Сбером подключил оплату улыбкой во всех российских офисах собственных продаж. С помощью специальных терминалов можно оплатить биометрией авиабилеты и любые дополнительные услуги перевозчика. Также авиакомпания вводит услугу Sleep+ на собственных рейсах. Теперь сервис доступен на ряде собственных международных и внутренних рейсов авиакомпании, выполняемых на широкофюзеляжных лайнерах Airbus A330, Airbus A350, Boeing 777.",
      "<strong>26 января.</strong> Аэрофлот обновил меню в классах Бизнес и Комфорт. Блюда бортовой кухни совместно с бренд-шефом АО «Аэромар» Русланом Муталиевым разработали победители Второго всероссийского гастрономического конкурса «SU-Шеф»: Дмитрий Решетников, бренд-шеф ресторана «MON CHOUCHOU» и «ZAZAZU (Санкт-Петербург), Игорь Шиянов, шеф-повар ресторана «Red Wall» (Нижний Новгород), Роман Чемеренко, бренд-шеф Bellini Gastronomic Ecosystem и совладелец ресторана Hello Wine (Красноярск), Евгений Кузнецов, бренд-шеф ресторанов «СибирьСибирь» (Москва, Тюмень, Новосибирск). Меню для бизнес-класса поменялось полностью, в классе Комфорт появились новые горячие блюда.",
      "<strong>31 января.</strong> Аэрофлот занял пятое место в рейтинге работодателей среди крупнейших компаний России. Исследование подготовила платформа онлайн-рекрутинга HeadHunter. Аэрофлот отмечен как привлекательный и открытый работодатель. Авиакомпания также находится на верхних строчках рейтинга популярности среди соискателей.",
      "<strong>31 января.</strong> Аэрофлот приступил к выполнению регулярных полётов из Москвы в Хошимин. Первый рейс SU292 вылетел из аэропорта Шереметьево с практически полной коммерческой загрузкой.",
      "<strong>9 февраля.</strong> Совет директоров Аэрофлота рассмотрел доклад о состоянии безопасности полётов в авиакомпании по итогам 2023 года. Уровень безопасности полётов Аэрофлота составил 99,973%, что превышает заданный на 2023 год уровень.",
      "<strong>13 февраля.</strong> Аэрофлот завершил формирование крупнейшего в России и Восточной Европе провайдера технического обслуживания и ремонта воздушных судов на базе предприятия «Аэрофлот Техникс». Среди российских организаций ТОиР «Аэрофлот Техникс» обладает крупнейшими площадями ангарных комплексов, которые позволяют одновременно разметить на базовое ТО до 19 воздушных судов, в том числе и широкофюзеляжные самолёты. Имеет самую разветвлённую сеть из 15 линейных станций в регионах России — от Калининграда до Камчатки.",
      "<strong>16 февраля.</strong> Аэрофлот по итогам 2023 года вошёл в число лидеров среди крупнейших российских госкомпаний по объёму закупок у малого и среднего бизнеса. Итоги закупок госкорпораций у субъектов малого и среднего предпринимательства (МСП) опубликованы на сайте Правительства России. Компания более чем в 5 раз нарастила стоимостной объём договоров, заключаемых с субъектами МСП, по сравнению с 2022 годом.",
      "<strong>1 марта.</strong> Аэрофлот выступил официальным перевозчиком и партнёром Всемирного фестиваля молодёжи, который проходил с 1 по 7 марта на федеральной территории «Сириус» в 30 км от Сочи. Авиакомпания обеспечивала комфортную перевозку участников фестиваля, а также организовала ряд знаковых активностей на выставочной площадке мероприятия.",
      "<strong>3 марта.</strong> В Москве стартовал Международный шахматный фестиваль Aeroflot Open. Фестиваль проходил с 3 по 7 марта в отеле The Carlton, Moscow. На турнир зарегистрировались более 450 спортсменов из 12 стран. Ежегодные шахматные соревнования были приостановлены в 2021 году, в связи с пандемией COVID-19.",
      "<strong>3 апреля.</strong> Аэрофлот одержал победу в трёх ключевых номинациях национальной авиационной премии «Крылья России» по итогам 2023 года. Аэрофлот признан лучшим в номинациях «Авиакомпания года. Пассажирские перевозки на внутренних авиалиниях» и «Авиакомпания года. Пассажирские перевозки на международных авиалиниях». По итогам онлайн-голосования на сайте премии Аэрофлот стал лауреатом также номинации «Авиакомпания года — лидер пассажирских симпатий». Кроме того, авиаперевозчик удостоился специальной награды Транспортной клиринговой палаты (ТКП) «30 лет в интересах отрасли и пассажиров».",
      "<strong>5 апреля.</strong> Аэрофлот стал победителем Национальной премии в области коммуникаций «Серебряный лучник» в номинации «Событийные коммуникации». Проект «Аэрофлот: Летайте с нами сквозь время», посвящённый серии ретрорейсов по знаковым российским направлениям в год 100-летия авиакомпании, был признан лучшим.",
      "<strong>8 апреля.</strong> Рейтинговое агентство «Эксперт РА» повысило ПАО «Аэрофлот» (тикер на Московской Бирже: AFLT) рейтинг кредитоспособности до уровня ruAА. Прогноз по рейтингу — стабильный. Ранее у компании действовал рейтинг на уровне ruA+ со стабильным прогнозом.",
      "<strong>12 апреля.</strong> Группа «Аэрофлот» публикует операционные результаты Группы за март и первый квартал 2024 года. По итогам первого квартала перевезено 11,5 млн пассажиров, на 21,7% больше по сравнению с аналогичным периодом 2023 года. Процент занятости пассажирских кресел составил 88,3%, увеличившись на 2,1 п.п. год к году на внутренних линиях показатель вырос на 2,2 п.п., до 90,4%.",
      "<strong>24 апреля.</strong> Аэрофлот в честь 79-й годовщины Победы в Великой Отечественной войне открывает продажу билетов в рамках традиционной ежегодной акции для ветеранов.",
      "<strong>1 мая.</strong> Аэрофлот открывает продажу билетов по субсидируемым государством тарифам для жителей Калининградской области на направлениях: Калининград — Москва — Калининград и Калининград — Санкт-Петербург — Калининград.",
      "<strong>13 мая.</strong> Аэрофлот открыл продажи субсидируемых государством билетов для учащихся высших учебных заведений Калининградской области. Воспользоваться перевозкой могут студенты очной формы обучения.",
      "<strong>1 июня.</strong> Аэрофлот выполнил благотворительный рейс SU1040 из Москвы в Сочи. Вырученные от продажи билетов средства авиакомпания направила на благотворительную помощь детям в новых регионах России.",
      "<strong>1 июня.</strong> Аэрофлот приступил к выполнению программы «Шаттл» между Москвой и Санкт-Петербургом. Рейсы в Северную столицу из Шереметьево и в обратном направлении будут вылетать с интервалом от 15 минут, а из Внуково и в обратном направлении — с интервалом от 30 минут. Ожидается, что общая частота полётов в рамках новой услуги составит свыше 70 рейсов в день.",
      "<strong>6 июня.</strong> Аэрофлот принимает участие в ПМЭФ-2024. В рамках мероприятия авиакомпания подписывает соглашения о сотрудничестве с вендором НОТА, Финансовым университетом при Правительстве РФ, группой компаний «АСТРА», губернатором Красноярского края, Обществом «Знание».",
      "<strong>15 июня.</strong> Аэрофлот выполнил первый рейс из Москвы в Чебоксары. 16 июня. Аэрофлот выполнил первый регулярный полёт по маршруту Москва — Элиста — Москва.",
      "<strong>30 июня.</strong> Аэрофлот отметил 100-летие воздушной линии Москва — Казань, одной из старейших воздушных линий авиакомпании. Рейс SU1276/1277, прибывающий в Казань в 14:40 по местному времени, был выполнен на ретроджете Airbus A320 «Добролёт» с кабинным экипажем в ретроуниформе.",
    ],
  },
  {
    name: "uz_airways",
    img_url: "/images/AK/Uzbekistan_Airways_logo.svg",
    title: "uz_airways",
    description: [
      "<p><strong>В летописи Узбекистана</strong> много событий, которые определили сегодняшний день и будущее страны. Авиакомпания «Uzbekistan Airways» была создана в 1992 году, данное событие ознаменовало новую эру в процессе становления гражданской авиации Республики Узбекистан. За это время бренд «Uzbekistan Airways» стал известен и признан во всем мире, как стабильный, безопасный и надежный авиаперевозчик.</p>",
      "<p>28 января 2017 года «Uzbekistan Airways» отметила свой четвертьвековой юбилей. Оглядываясь назад, на пройденный путь, можно убедиться в том, что  каждый год был не менее плодотворным, чем предыдущий. Наглядное тому подтверждение – возрастающий экономический потенциал и имидж гражданской авиации Узбекистана.</p>",
      "<p>Авиакомпании есть чем гордиться. Современные комфортабельные авиалайнеры Узбекистана регулярно приземляются и взлетают в более чем 50 городах Европы и Азии, выполняют чартерные рейсы от Аляски до Новой Зеландии. Пилоты Узбекистана освоили эксплуатацию воздушных судов А320, Боинг-757/767, и сегодня управляют самолетами нового поколения -  Дримлайнер. Их мастерство и потенциал хорошо известны в мире и высоко оцениваются международными организациями.</p>",
      "<p><strong>АО «Uzbekistan Airways»</strong> признана лучшим в мире эксплуатантом самолетов Boeing 787 Dreamliner. По итогам 6 месяцев (декабрь 2016г. – май 2017г.) АО «Uzbekistan Airways» показала наилучший результат  в мире среди эксплуатантов  Boeing 787 Dreamliner по безаварийной эксплуатации «лайнеров мечты», обеспечив  на данном типе ВС 100 процентную безопасность и регулярность выполнения полетов, за что  была удостоена награды  корпорация «Boeing» за успешную, безаварийную эксплуатацию самолетов данного типа.</p>",
      "Сегодня на «лайнерах мечты» успешно выполняются рейсы  в Дубаи, Дели, Амритсар, Стамбул, Тель-Авив, Сеул, Франкфурт, Москву, Куала-Лумпур и Сингапур, а также через Ригу в Нью-Йорк.  27 июля 2017 года впервые в истории авиакомпания «Uzbekistan Airways» осуществила первый прямой полет на Дримлайнере по маршруту Ташкент - Нью-Йорк - Ташкент.</p>",
      "<p><strong>За прошедшие годы</strong> в отрасли реализовано множество крупных проектов. Модернизирована наземная инфраструктура аэропортов, полностью обновлен авиапарк воздушных судов и освоены новые самолеты. Открыты новые маршруты, построены новые терминалы и аэровокзалы с высокой пропускной способностью, внедрены передовые технологии.",
      "<p><strong>В аэропортах</strong> введены в эксплуатацию современные мини-центры Управления воздушным движением (УВД) и новая  аэронавигационная  система. Каждый лайнер в небе Узбекистана находится под постоянным контролем высококвалифицированных диспетчеров Центра «Узаэронавигация».</p>",
      "<p><strong>Все 11 аэропортов</strong> республики имеют статус «международных» и обеспечены надежной и совершенной досмотровой техникой. Современные пассажирские терминалы оснащены всеми удобствами и оборудованием, отвечающим международным стандартам. В столичном аэропорту к услугам пассажиров зал вылета с  повышенным уровнем комфортности Business Lounge  «Uzbekistan airways».</p>",
      "<p><strong>Международный интермодальный центр логистики,</strong> созданный на базе аэропорта  «Навои», является одним из крупнейших и наиболее высокотехнологичных авиагрузовых комплексов региона. Сегодня он соединяет Узбекистан с основными логистическими центрами Евразии – Франкфурт, Милан, Вена, Осло, Базель,  Сарагоса, Дубай, Дели, Инчон, Тяньцзинь, Ханой и Шанхай.</p>",
      "<p><strong>«Нам важен каждый пассажир!»</strong> - этот  девиз остается всегда актуальным и в деятельности предприятия «Кетринг», предоставляющем на борт самолетов вкусную еду. С каждым годом к качеству бортового питания предъявляются все более высокие требования, и коллектив постоянно работает над качеством питания, совершенствованием процесса его приготовления и меню.</p>",
      "<p><strong>На международном рынке</strong> авиационных услуг все больше заказчиков, желающих сотрудничать с узбекской авиакомпанией в сфере Технического обслуживания и ремонта воздушных судов (ТОиР). Благодаря собственной авиаремонтной базе национальный авиаперевозчик может самостоятельно и качественно поддерживать летную годность своих самолетов и оказывать услуги по техническому ТОиР ВС зарубежным авиакомпаниям.</p>",
      "<p><strong>На авиапредприятии «Uzbekistan</strong> <strong>Airways</strong> <strong>Technics»,</strong> выполняющем все виды ТОиР самолетов различных модификаций, включая Boeing 787 Dreamliner, создан Центр по ремонту композитных материалов, который открыл большие перспективы в развитии деятельности данного предприятия по ремонту современных ВС.</p>",
      "<p><strong>Авиакомпания</strong> создала собственную базу подготовки, обучения и повышения квалификации своего персонала. Учебно-тренировочный центр имеет профессиональный преподавательский состав и Тренажерным центр с различными видами тренажеров, включая полнопилотажные Full-flight- симуляторы ВСА320 и Боинг-767, что позволяет предлагать услуги по подготовке и обучению летного состава зарубежным компаниям.</p>",
      "<p><strong>Планы национального авиаперевозчика, </strong>крупные по масштабам в СНГ<strong>, </strong>демонстрируют динамику развития и прогресс гражданской авиации Узбекистана. Подписан контракт на получение еще 4-х самолетов Boeing 787 Dreamliner.</p>",
      "<p><strong>Реализация этих проектов</strong> в ближайшей перспективе выведет гражданскую авиацию Узбекистана на качественно новый уровень, обеспечивающий ее статус и имидж на международной арене. Коллектив авиакомпании нацелен на лидерство на рынке авиаперевозок. И конечно, самым приоритетным направлением в его деятельности, как и прежде, будет обеспечение безопасности полетов и пассажиров, оставаясь верным своим критериям – стабильность, надежность и комфорт.</p>",
    ],
  },
  {
    name: "turkish",
    img_url: "/images/AK/Turkish_Airlines_logo.svg",
    title: "turkish",
    description: [
      "<h2>Путешествие на высочайшем уровне</h2>",
      "<p>Удовлетворение потребностей клиентов - наш главный приоритет, и мы делаем все возможное, чтобы ваше путешествие прошло максимально гладко.</p>",
      "<p>В 2000 году мы создали для вас программу Miles &amp Smiles, предоставляющую эксклюзивные привилегии. В 2008 году Turkish Airlines присоединилась к глобальной конфедерации авиакомпаний Star Alliance. Повышение уровня услуг кейтеринга было призвано сделать ваше путешествие максимально приятным. Наше сотрудничество с Turkish Do &amp Co предлагает пассажирам возможность насладиться вкусными блюдами над облаками, специально приготовленными шеф поварами на борту самолёта.</p>",
      "<p>Наша бортовая развлекательная система наполнена новейшими фильмами, музыкой, играми и многим другим, обеспечивая нашим пассажирам приятное и веселое путешествие. Кроме того, пассажиры остаются на связи с миром с помощью предлагаемой нами услуги Wi-Fi во время полета.</p>",
      "<h2>Мы летаем в большее количество стран, чем любая другая авиакомпания</h2>",
      "<p>Идти в ногу с технологиями - важный компонент наших инновационных целей и обеспечения того, чтобы у нас был самый молодой и современный флот в Европе. Наш флот процветал благодаря приобретению высокотехнологичных, экономичных и экологически безопасных самолетов, обеспечивающих высокий уровень комфорта.</p>",
      "<p>Благодаря нашей непревзойденной маршрутной сети, молодому и современному флоту, комфортабельным креслам и вкусным угощениям мы заслужили титул Лучшей авиакомпании Европы. С огромным энтузиазмом и амбициями мы летаем почти во все страны мира. Мы преуспеваем в уникальных открытиях, которыми делимся с нашими пассажирами. Turkish Airlines запечатлела свое имя с помощью всемирно известных спонсоров и рекламы.</p>",
      "<p>Наша растущая тяга к путешествиям с годами принесла нам звание авиакомпании, которая летает в большинство стран мира. Сегодня мы выполняем рейсы в 120 стран из нашего нового дома, аэропорта Стамбула, с молодым флотом из 460 самолетов. Мы с гордостью несем турецкий флаг по всему миру, открывая двери в широкий мир для наших пассажиров.</p>",
    ],
  },
  {
    name: "belavia",
    img_url: "/images/AK/Belavia_logo.png",
    title: "belavia",
    description: [
      "<h3>Краткая информация о компании:</h3>",
      "<p>ОАО «Авиакомпания «Белавиа» - крупнейший авиаперевозчик Республики Беларусь. Базируется в Национальном аэропорту Минск и осуществляет регулярные рейсы в 9 государств. Создана 5 марта 1996 года. С 1997 года является действительным членом Международной ассоциации воздушного транспорта (IATA), с 2010 – членом Ассоциации европейских региональных авиакомпаний (ERAA).</p>",
    ],
  },
  {
    name: "ural",
    img_url: "/images/AK/ural.svg",
    title: "ural",
    description: [
      "<h3>О нас</h3>",
      "«Уральские авиалинии» входят в ТОП крупнейших российских авиакомпаний. Постоянные пассажиры ценят, что компания работает над улучшением сервиса, обновляет воздушный парк и каждый год открывает все новые направления для перелетов в города России, СНГ и страны дальнего зарубежья. География полетов насчитывает уже более 200 направлений и это ― не предел для нас.",
      "<h3>Член международной ассоциации воздушного транспорта IATA</h3>",
      "<p> Авиакомпания имеет регистрацию в реестре IOSA по итогам международного аудита на соответствие стандартам эксплуатационной безопасности до 25 апреля 2022 года. Обязательная сертификация IOSA — это часть политики компании, направленной на постоянное совершенствование своей деятельности.",
      "<p>Осенью 2015 года «Уральские авиалинии» приняли участие в проверке системы государственного контроля за обеспечением безопасности полетов РФ. Проверку проводили аудиторы Международной организации гражданской авиации ICAO.</p>",
      "<p>По итогам проверки «Уральские авиалинии» получили благодарность от Министерства транспорта РФ. </p>",
    ],
  },
  {
    name: "s7",
    img_url: "/images/AK/S7_new_logo.svg",
    title: "s7",
    description: [
      "<h1>О компании</h1>",
      "<p>S7 Airlines— крупнейшая частная авиакомпания России ссамым современным парком воздушных судов нароссийском рынке авиаперевозок.</p>",
      "<p>S7Airlines входит втройку лучших авиакомпаний Восточной Европы вавторитетном международном рейтинге Skytrax. Порезультатам 2018года S7Airlines стала самой пунктуальной российской авиакомпанией, заняв шестое место вевропейском рейтинге пунктуальности авиакомпаний OAG Punctuality League 2019. </p>",
      "<p>S7Airlines— один изсамых экологичных авиаперевозчиков мира, пооценке экологической организации Atmosfair. Авиакомпания занимает 16место вмировом рейтинге.</p>",
    ],
  },
  {
    name: "qanot",
    img_url: "/images/AK/Qanot_Sharq_logo.svg",
    title: "qanot",
    description: [
      "<h1>О компании</h1>",
      "<p><i>Авиакомпания «Qanot Sharq» начала свою деятельность в 1998 году. Прокручивая прошедший период как кинопленку, можно вспомнить наиболее яркие моменты, которые произошли в мире, нашей стране и коснулись каждого из нас</i></p>",
      "<p><i>За неполные четверть века мы с вами стали свидетелями политических, экономических, социальных и экологических преобразований, сегодня мы практически непрерывно включены в интернет, используем девайсы и едва ли не живём в социальных сетях.</i></p>",
      "<p><i>Узбекистан за прошедшие годы обновился: сталсуверенным государством, вошёл в рыночную экономику,является одним из основателей ШОС, либерализовал валютный рынок, упростил визовый режим, создал собственный автопром.</i></p>",
      "<p><i>Без малого за 25 лет наша авиакомпания стремительно развивалась – мы выполнили сотни успешных грузопассажирских авиарейсов на Ил-76, Boing 757. Менялись условия работы и совершенствовались способы организации национальных и международных грузо и пассажироперевозок.</i></p>",
      "<p><i>Да, наш коллектив за эти годы тоже рос и формировался .Отрадно, что сегодня в авиакомпании работают не только те, кто стоял у ее истоков, но и наши дети – поколение Независимого Узбекистана. Молодежь под чутким руководством наставников, уверено движется вперед, пользуясь возможностями, создаваемыми Президентом Узбекистана Шавкатом Мирзиеевым.</i></p>",
      "<p><i>Указ Президента Республики Узбекистан от 27.11.2018 г. № УП-5584 «О мерах по кардинальному совершенствованию гражданской авиации Республики Узбекистан и Постановление Президента Республики Узбекистан от 06.03.2019 г. № ПП-4230 «О мерах по кардинальному совершенствованию системы грузовых и пассажирских перевозок» сыграли ключевую роль в активизации деятельности нашей авиакомпании.</i></p>",
      "<p><i>Эти документы создали условия для развития конкуренции на рынке авиаперевозок и привлечения инвестиций в отрасль. Сегодня авиакомпания «Qanot Sharq» с гордостью отмечает, что является обладательницей четырёх воздушных судов, выполняющих не только внутриреспубликанские, но и международные рейсы, а число перелётов и пассажиров растет буквально по часам.</i></p>",
      "<p><i>В чем сила авиакомпании «Qanot Sharq»? Наша команда всегда уверена в том, имеет полное право представлять Узбекистан на самом высоком уровне. Это чувство уверенности за страну, за свою компанию мы привили нашим детям и вместе строим сильную, крепкую команду,новый прогрессивный Узбекистан.</i></p>",
    ],
  },
  {
    name: "apgAS",
    img_url: "/images/AK/apg-logo-2023-white.png",
    title: "apgAS",
    description: [
      "<h1>О компании APG Network</h1>",
      "<p>Авиакомпании постоянно ищут новые возможности получения прибыли, решения по снижению затрат на дистрибуцию и способы повышения доходности. Благодаря инновациям и обширному опыту в сфере дистрибуции авиакомпаний, сеть APG за последние три десятилетия превратилась в ключевого глобального игрока с репутацией поставщика услуг, способного достичь этих целей. APG не только обеспечивает существенное сокращение расходов за счет аутсорсинговых решений в области продаж пассажирских и грузовых перевозок, маркетинга, формирования тарифов и поддержки клиентов, но и предлагает услуги расчетного плана, интерлайн и код-шеринговые решения для расширения глобального охвата авиакомпании. APG гордится тем, что уже сотрудничает с более чем 200 авиакомпаниями, работающими по всем моделям дистрибуции.</p>",

      "<p>Мы также продолжаем внедрять инновации и недавно запустили APG Platform, наше новое поколение решений для дистрибуции, основанное на технологии, соответствующей стандарту NDC.</p>",

      "<p>Наш портфель решений для дистрибуции авиабилетов поддерживается нашей глобальной сетью, охватывающей более 170 стран. Наша миссия всегда заключалась в том, чтобы обеспечить действительно глобальный охват и быть лучшими на каждом рынке благодаря нашим опытным местным командам менеджеров и их обширным связям в каждой стране.</p>",

      "<p>Штаб-квартира APG находится в Париже, а центральная коммерческая команда и совет директоров возглавляются президентом сети и включают в себя по одному вице-президенту на основную географическую зону мира и по одному исполнительному директору по продуктам.</p>",

      "<p>Переведено с помощью DeepL.com (бесплатная версия)</p>",
    ],
  },
  {
    name: "pobeda",
    img_url: "/images/AK/Logo-pobeda-en.svg",
    title: "pobeda",
    description: [
      "<h1>О компании</h1>",
      "<p>«Победа» начала летать <strong>1 декабря 2014 года</strong>. Первый в России лоукостер стал самым успешным проектом в истории российской авиации</p>",
      "<p>Лидер по средней загрузке рейсов - около <strong>95%</strong>. В тройке лидеров по пассажиропотоку по итогам 2023 года!</p>",
      "<p>Сохраняет лидерство по показателю среднесуточного налёта на самолетах <strong>Boeing 737-800</strong></p>",
      "<h2>«Победу» создает команда уникальных специалистов,<br><span style='color: rgb(20, 160, 230)'>которые слаженно работают на земле и в небе!</span></h2>",
      "<h2>Наши ценности</h2>",
      "<h4>Мы - люди дела</h4>",
      "<ul style='list-style: none'><ul><li style='list-style: disc; margin-left: 24px'>Внедряем передовые технологии и задаем тренды в отрасли</li><li style='list-style: disc; margin-left: 24px'>Предлагаем выгодный конструктор цен –вы платите только за нужные услуги</li><li style='list-style: disc; margin-left: 24px'>Прикладываем максимумусилий, чтобы вы летали часто и недорого</li></ul></ul>",
      "<h4>Мы все на «ты»</h4>",
      "<ul style='list-style: none'><li style='list-style: disc; margin-left: 24px'>Слушаем и слышим вас - ценим обратную связь</li><li style='list-style: disc; margin-left: 24px'>Информируем о наших правилах, чтобы каждое ваше путешествие было комфортным</li><li style='list-style: disc; margin-left: 24px'>Наши двери всегда открыты для новых идей и предложений</li></ul>",
      "<h2>Парк самолётов<br>авиакомпании <span style='color: rgb(20, 160, 230);'>Победа</span></h2>",
      "<p>В парке «Победы» 42 <span style='color: rgb(20, 160, 230);'>суперсовременных</span> самолёта<span style='color: rgb(20, 160, 230);'>Boeing 737-800</span>. В салонах Sky Interior — вместительные багажные полки, удобные кожаные кресла и уникальное освещение, меняющее цвет. Выбирая «Победу», Вы можете быть уверены, что экономите на перелёте, но не на комфорте и безопасности.</p>",
    ],
  },
  {
    name: "airastana",
    img_url: "/images/AK/Air_Astana_logo.svg",
    title: "airastana",
    description: [
      "<h1>О нас</h1>",
      "<span>Air Astana – одна из крупнейших авиакомпаний Центральной Азии, предлагающая cервис, удостоенный наград, на современных самолетах по направлениям от Дальнего Востока до Западной Европы.</span>",
      "<h2>Миссия, цели и ценности</h2>",
      "<p>Наша миссия – создавать одну из лучших авиакомпаний в мире из самого сердца Евразии. Наши цели определяют это стремление:</p>",
      "<ul style='list-style-type: disc; margin-left: 25px; margin-top: 16px'><li style='margin-bottom: 16px'>Мы обеспечиваем самые высокие стандарты безопасности.</li><li style='margin-bottom: 16px'>Мы есть и должны быть самой надежной авиакомпанией в регионе с превосходным качеством обслуживания клиентов.</li><li style='margin-bottom: 16px'>Мы обеспечиваем прибыльный рост парка воздушных судов, географии полетов и предлагаемого продукта.</li><li style='margin-bottom: 16px'>Мы придерживаемся самых высоких стандартов честности и деловой этики.</li><li style='margin-bottom: 16px'>Мы привлекаем, оцениваем, вознаграждаем сотрудников по заслугам и предоставляем каждому возможность максимальной реализации своего потенциала.</li><li style='margin-bottom: 16px'>Мы – социально ответственная организация.</li><li>Мы являемся глобальным послом Казахстана.</li></ul>",
      "<h2>История</h2>",
      "<p>Авиакомпания Air Astana была зарегистрирована в 2001 году, и 15 мая 2002 года выполнила свой первый рейс Алматы - Астана. Инаугурацию рейса посетили президент Республики Казахстан Нурсултан Назарбаев и cэр Ричард Эванс, представляя двух акционеров авиакомпании - правительство Республики Казахстан и британскую компанию BAE Systems PLC соответственно. В совместном предприятии с долями участия правительства - 51%, принадлежащий АО «Фонд национального благосостояния «Самрук-Казына», и 49% компании BAE Systems PLC партнерские отношения акционеров оставались стабильными в течение всей истории развития авиакомпании.</p>",
      "<p>Air Astana сертифицирована Европейским агентством авиационной безопасности (EASA) на выполнение технического обслуживания воздушных судов по требованиям директивы 145. В марте 2019 года Air Astana без замечаний прошла сертификационный аудит Европейского агентства безопасности полетов (EASA) для авиакомпаний стран, не входящих в Европейский Союз (TCO). Air Astana также сертифицирована Европейским агентством безопасности полетов (EASA) на проведение обучения инженерно-технического персонала в соответствии с требованиями EASA Part 147. Демонстрируя свою приверженность безопасности полетов, авиакомпания успешно прошла аудит по операционной безопасности Международной ассоциации воздушного транспорта (IOSA) в 8-й раз в мае 2021 года.</p>",
      "<h2>Рост парка воздушных судов</h2>",
      "<p>Располагая в настоящее время авиапарком из 54 самолетов западного производства, средний возраст которых составляет 5,2 года, авиакомпания Air Astana расширила свою деятельность до более чем 40 внутренних и международных маршрутов. Для обеспечения высоких стандартов обслуживания авиакомпания опирается на персонал из более чем 6000 высококвалифицированных сотрудников. С осени 2005 года авиакомпанию Air Astana возглавляет Питер Фостер, руководитель в области авиационного бизнеса со стажем более 30 лет. Демонстрируя адаптивность и инновации, авиакомпания достигла важной вехи в мае 2019 года, успешно запустив FlyArystan – первого в регионе бюджетного авиаперевозчика.</p>",
    ],
  },
  {
    name: "flyaristan",
    img_url: "/images/AK/Fly-aristan.png",
    title: "flyaristan",
    description: [
      "<h1>О компании</h1>",
      "<p style='font-weight: 400;'>29 марта 2019 года. По состоянию на июнь 2022 года рейсы выполняются на самолётах Airbus: A320-200 и A320neo. Всего в эксплуатации на данный момент находится 11 бортов. Клиентам предлагают места однокласной компоновки экономкласса. Авиакомпания совершает внутренние и международные рейсы.</p>",
    ],
  },
];

import i18next from "i18next";
import { Link } from "react-router-dom";
import Card from "../../ui/Card";
import { data } from "../PartnersComponent/data";
import { Cards, Title, Wrapper } from "./styles";

export const SuppliersComponent = () => {
  const { t } = i18next;

  return (
    <Wrapper>
      <Title>{t("partners")}</Title>
      <Cards>
        {data.map(item => {
          const destination = {
            pathname: `${item.name}`,
            state: { ...item.name },
          };
          return (
            <Link key={item.name} to={destination}>
              <Card imgSrc={item.img_url} title={item.title} />
            </Link>
          );
        })}
      </Cards>
    </Wrapper>
  );
};

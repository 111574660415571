import { ContentType, request } from "../api";
import { baseParams } from "../common";
import { formattedRoutesData } from "./searchFlights";

const searchBrandFares = (result) => {
  const { supplier } = result.included;
  const newResult = { ...result };

  newResult.flights = result?.flights.map(recommendation => {
    const supplierData = supplier[recommendation["validating_supplier"]];
    const newRecommendation = { ...recommendation };
    newRecommendation["lowcost"] = supplierData?.lowcost || false;
    newRecommendation.routes = formattedRoutesData(recommendation.routes);
    return newRecommendation;
  });

  return newResult;
};

export const getBrandFares = async (data = {}, params = {}) => {
  const response = await request({
    path: "/brand-fare",
    method: "POST",
    body: { ...baseParams, ...data },
    type: ContentType.Json,
    format: "json",
    ...params,
  });

  return searchBrandFares(response?.data);
};

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SuppliersComponent } from "../../components/info/SuppliersInfoComponent";
import { setSearchBlockCompact } from "../../store/appConfigsSlice";

const SuppliersPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchBlockCompact(true));
  }, []);

  return <SuppliersComponent />;
};

export default SuppliersPage;

import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { LayoutComponent } from "../components";

import {
  AuthPage,
  BalancePage,
  BookingPage,
  ContactsPage,
  ForgotPasswordPage,
  HomePage,
  InfoPaymentPage,
  OrderPage,
  OrdersPage,
  PrivacyPolicyPage,
  PublicOfferPage,
  RegistrationPage,
  ResetPasswordPage,
  ResultPage,
  RulesOfUsePage,
  SuppliersPage,
  TransactionsPage,
} from "../pages";
import PartnersInfoPage from "../pages/PartnersInfoPage";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutComponent />}>
      <Route index element={<HomePage />} />
      <Route path="/result/:searchId" element={<ResultPage />} />
      <Route path="/booking" element={<BookingPage />} />
      <Route path="/order/:orderId/:sessionId" element={<OrderPage />} />
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/user/balance" element={<BalancePage />} />
      <Route path="/payment-info" element={<InfoPaymentPage />} />
      <Route path="/privacy-policy-info" element={<PrivacyPolicyPage />} />
      <Route path="/public-offer-info" element={<PublicOfferPage />} />
      <Route path="/rules-of-use-info" element={<RulesOfUsePage />} />
      <Route path="/transactions" element={<TransactionsPage />} />
      <Route path="/contacts" element={<ContactsPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/supplier" element={<SuppliersPage />} />
      <Route path="/supplier/:airline" element={<PartnersInfoPage />} />
    </Route>
  )
);

export default routes;

import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 20,
  flexGrow: 1,

  [theme.down("lg")]: {
    width: "100%",
  },
}));

export const ShowMoreButtonBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  ...theme.paddings(15),
}));

export const ShowMoreButton = styled("button")(({ theme }) => ({
  ...theme.paddings(5, 10),
  width: 170,
  height: 42,
  color: "#fff",
  backgroundColor: "#E63946",
  border: "1px solid #E63946",
  fontSize: 14,
  cursor: "pointer",
	transition: "all 0.3s ease-out",

	"&:hover": {
		backgroundColor: [theme.palette.hoverButtonBg],
		border: "1px solid #D00F1E",
	},
}));

// headerBg: "rgba(225, 242, 250, 1)",
const palette = {
	headerBg: "#1D3557",  //457B9D
  footerBg: "#457B9D",  //1D3557
  searchFormBg: "rgba(0, 0, 0, .5)",
  searchButtonBg: "#E63946",
  removeRouteButtonBg: "#ff6663",
	hoverButtonBg: "#D00F1E",

  white: "#fff",
};

export default palette;

import { useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setShowSearch } from "../../store/appConfigsSlice";

import { ResetPasswordForm } from '../../components';

const ResetPasswordPage = () => {
	const dispatch = useDispatch();
	
	useEffect(() => {
    dispatch(setShowSearch(false));
  }, []);

	return (
		<ResetPasswordForm />
	);
};

export default withTranslation()(ResetPasswordPage);

import { useState } from "react";
import { useEffect } from "react";

import i18next from "i18next";

import { InputComponent, DropdownComponent } from "../../ui";
import { CountryItem, CountryLabel, Wrapper } from "./style";

const BookingDocumentTypeSelectComponent = ({
  availableDocumentTypes,
  documentValue,
  touched,
  error,
  success,
  onChange,
  onBlur,
  clearFieldError,
}) => {
  const { t } = i18next;
  const [value, setValue] = useState(availableDocumentTypes.find(type => type.value === documentValue)?.label);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleOnSelect = type => {
    setValue(type.label);
    onChange(type.value);
    setShowDropdown(false);
  };

  const renderDocuments = () => {
    return availableDocumentTypes.map((type, index) => {
      return (
        <CountryItem key={index} onClick={() => handleOnSelect(type)}>
          <CountryLabel>{type.label}</CountryLabel>
        </CountryItem>
      );
    });
  };

  useEffect(() => {
    setValue(availableDocumentTypes.find(type => type.value === documentValue)?.label);
  }, [documentValue, availableDocumentTypes]);

  return (
    <Wrapper>
      <InputComponent
        value={value}
        label={t("document")}
        placeholder={t("document")}
        touched={touched}
        error={!showDropdown ? error : null}
        onBlur={onBlur}
        onClick={() => setShowDropdown(true)}
        onFocus={clearFieldError}
        success={success}
        readOnly
        required
      />

      {showDropdown && (
        <DropdownComponent show={showDropdown} onClickOutside={() => setShowDropdown(false)} noPadding>
          {renderDocuments()}
        </DropdownComponent>
      )}
    </Wrapper>
  );
};

export default BookingDocumentTypeSelectComponent;

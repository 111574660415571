import { useCallback, useState } from "react";

import { useFormik } from "formik";
import i18next from "i18next";
import { Link, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { resetPassword } from "../../../protocols";

import { useEventListener } from "../../../hooks";

import { DotsLoadingComponent, InputComponent } from "../../ui";

import {
	BackButton,
	BackButtonBlock,
	ButtonBlock,
	CardWrapper,
	FormSubtitle,
	FormTitle,
	SubmitButton,
	SubmitButtonLoadingCover,
	Wrapper
} from "../../auth/RegistrationForm/style";

const ResetPasswordForm = () => {
  const { t } = i18next;
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(0);

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(6, t("must_field_characters")).required(t("required")),
    confirmationPassword: Yup.string()
        .min(6, t("must_field_characters"))
        .required(t("required"))
        .oneOf([Yup.ref('password'), null], t("passwords_must_match")),
});


  const handleFormSubmit = async (values) => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const resetResult = await resetPassword(values);

      if (resetResult?.data?.error_code) {
        setFieldError("login", resetResult.error_desc);
      } else {
        setStep(1);
      }
    } catch (error) {
      console.log({ error });
      setFieldError("login", error.response.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = useFormik(
    {
      initialValues: {
        password: "",
        confirmationPassword: "",
        hash,
      },
      validationSchema,
      onSubmit: handleFormSubmit,
      validateOnBlur: true,
    }
);

  const handleKeyPress = useCallback(e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      handleSubmit();
    }
  }, [handleSubmit]);

  useEventListener("keypress", handleKeyPress);

  return (
    <Wrapper>
			<CardWrapper>
				<FormTitle>
					{!step ? "Сброс пароля пользователя" : "Новый пароль успешно сохранен!"}
				</FormTitle>

				<FormSubtitle>
          {!step ? "Введите новый пароль" : "Вы можете авторизоваться используя новый пароль"}					
				</FormSubtitle>

        {!step && (
          <>
            <InputComponent
              value={values.password}
              onChange={handleChange("password")}
              type="password"
              placeholder={t("come_up_your_pass")}
              label={t("password_text")}
              error={errors.password}
              onBlur={handleBlur("password")}
              touched={touched.password}
              required
            />

            <InputComponent
              value={values.confirmationPassword}
              onChange={handleChange("confirmationPassword")}
              type="password"
              placeholder={t("confirmation_activation_password")}
              label={t("password_text")}
              error={errors.confirmationPassword}
              onBlur={handleBlur("confirmationPassword")}
              touched={touched.confirmationPassword}
              required
            />

            <ButtonBlock>
              <SubmitButton
                onClick={handleSubmit}
                type="button"
              >
                {t("send")}
                {isSubmitting && (
                  <SubmitButtonLoadingCover>
                    <DotsLoadingComponent color="#fff" />
                  </SubmitButtonLoadingCover>
                )}
              </SubmitButton>
            </ButtonBlock>
          </>
        )}

        {step === 1 && (
          <BackButtonBlock>
            <BackButton>
              <Link to="/auth">Войти</Link>
            </BackButton>
          </BackButtonBlock>
        )}
			</CardWrapper>
    </Wrapper>
  )
};

export default ResetPasswordForm;

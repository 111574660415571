const uz = {
  location_map: "Bizning manzilimiz xaritada",
  location: "Manzil",
  agreement_text:
    "Saytga kirish orqali siz Maxfiylik siyosati va shaxsiy ma'lumotlarni yig'ish va qayta ishlash qoidalariga rozilik bildirasiz.",
  login_title_text: "Shaxsiy kabinetingizga kiring",
  login_text: "Kirish",
  password_text: "Parol",
  forgot_password_text: "Parolni unutdingizmi?",
  reset_password_text: "Shu erda parolingizni tiklang",
  registration_text: "Roʻyxatdan oʻtish",
  new_customer: "Yangi foydalanuvchi?",
  logged_user_text: "Ro'yxatdan o'tkanmisiz?",
  enter_text: "Tizimga kirish",
  invalid_password: "Kamida 6 ta belgi",
  invalid_email: "Yaroqsiz email",
  required: "Majburiy maydon",
  travel_time: "Sayohat vaqti",
  flight: "Parvoz",
  terminal: "Terminal",
  baggage: "Yuk",
  hand_luggage: "Qo'l yuki",
  refund_ticket: "Chiptani qaytarish",
  change: "Chipta almashinuvi",
  no_refund: "Qaytib berilmaydi",
  yes_refund: "qaytib berish mavjud",
  no_change: "Almashtirilmaydi",
  yes_change: "Almashtirish mavjud",
  yes_baggage: "Bagajingiz mavjud",
  no_baggage: "Bagajingiz mavjud emas",
  yes_hand_luggage: "Qo'l yuki mavjud",
  no_hand_luggage: "Qo'l yuki mavjud emas",
  show_rules: "Qoidalarni ko'rsatish",
  total: "Jami",
  passengers: "Yo'lovchilar",
  select_from_list: "Roʻyxatdan tanlang",
  name_as_in_document: "Hujjatdagi kabi ism",
  middlename_as_in_document: "Hujjatdagi kabi otasining ismi",
  surname_as_in_document: "Hujjatdagi kabi familiya",
  date_of_birth: "Tug'ilgan sana",
  gender: "Jinsiy",
  citizenship: "Fuqarolik",
  age: "Yosh",
  document: "Hujjat",
  payer: "Xaridor",
  for_payer_mess:
    "Biz sizning bandlovingiz haqidagi ma'lumotlarni elektron pochta orqali yuboramiz. Jadvalda o'zgarishlar yuz berganda, telefon shoshilinch bildirishnomalar uchun kerak",
  series_and_number: "Seriya va raqam",
  valid_until: "Amal qilish muddati",
  airline_bonus_card: "Aviakompaniya bonus kartasi",
  number_bonus_card: "Bonus karta raqami",
  phone_number: "Telefon raqami",
  contact_number: "+998 97 285 00 06 (har kuni 9:00 dan 23:00 gacha)",
  email: "Elektron pochta",
  workH: "Ish tartibi",
  working_hours: "Biz har kuni soat 09:00 dan 23:00 gacha aloqadamiz.",
  how_to_read_rules: "Qoidalarni qanday o'qish kerak",
  tariff_rules: "Tarif qoidalari",
  book_and_proceed_to_payment: "Band qiling va to'lovni davom eting",
  agreement_book_text: '"Bandlash va toʻlovni davom ettirish” tugmasini bosish orqali siz rozilik bildirasiz',
  agreement_book_link: "aviakompaniya tariflari shartlari",
  everywhere_local_time: "Mahalliy ketish va kelish vaqtlari hamma joyda ko'rsatilgan",
  duration: "davomiyligi",
  in: "в",
  no_data_contact_technical_support: "Maʼlumot yoʻq. Texnik yordamga murojaat qiling.",
  back_to_orders: "Buyurtmalarga qaytish",
  incorrect_date_from_adt: "Katta yoshlilar uchun sana noto‘g‘ri",
  incorrect_date_from_chd: "Bola uchun sana noto‘g‘ri",
  incorrect_date_from_inf: "Chaqaloq uchun sana noto‘g‘ri",
  incorrect_date: "Notog'ri sana",
  check_date_and_change_doc: "Tug'ilgan sanani tekshiring yoki hujjat turini o'zgartiring",
  warning_inf_date:
    "Aviakompaniya yangi tug'ilgan chaqaloqqa parvoz qilish uchun ruxsatnoma sertifikatini talab qilishi mumkin.",
  ps_should_be_10_digits: "Rossiya pasportida 10 ta raqam bo'lishi kerak",
  psp_should_be_9_digits: "Xorijiy mamlakatlarga Rossiya pasporti 9 ta raqamdan iborat bo'lishi kerak",
  incorrect_doc_number: "Hujjat raqami noto‘g‘ri",
  hint_for_ps_doc: "Iltimos, seriya va pasport raqamini ko'rsating, masalan, 1234567890",
  hint_for_psp_doc: "Pasport raqamingizni kiriting, masalan, 123456789",
  hint_for_sr_doc: "ltimos, tug'ilganlik haqidagi guvohnoma raqamingizni ko'rsating, masalan, IXYaYa123456",
  hint_for_np_doc: "Iltimos, seriya va hujjat raqamini ko'rsating",
  male: "Erkak",
  female: "Ayol",
  russian_passport: "Rossiya pasporti",
  birth_certificate: "Rossiya Federatsiyasining tug'ilganlik haqidagi guvohnomasi",
  international_passport: "Rossiya Federatsiyasining chet el pasporti",
  foreign_document: "Xorijiy hujjat",
  place_one: "joy",
  place_more: "joylar",
  place_many: "joy",
  customer_support: "Mijozlarni qo'llab-quvvatlash",
  financial_matters: "Moliyaviy savollar",
  agreement_footer_text: "Yozma roziligisiz materiallardan foydalanish taqiqlanadi",
  flight_schedule: "Parvoz jadvali",
  offer: "Oferta",
  question_answer: "Savol-javob",
  jobs: "Bo'sh ish o'rinlari",
  facebook: "Facebook",
  about_us: "Biz haqimizda",
  currency: "Valyuta",
  language: "Til",
  search: "Qidirmoq",
  personal_info: "Shaxsiy ma'lumot",
  personal_area: "Shaxsiy kabinet",
  not_found_page: "Siz ochmoqchi boʻlgan sahifa topilmadi",
  to_main: "Asosiy betga",
  log_out: "Tizimdan chiqish",
  session: "Session",
  error_500_text:
    "Nimadir noto‘g‘ri ketdi shekilli, lekin biz muammodan xabardormiz va uni tuzatish ustida ishlayapmiz. Noqulaylik uchun uzr so'raymiz.",
  lang_ru: "rus",
  lang_en: "Ingliz",
  balance: "Balans",
  otb: "Balans",
  own_funds: "O'z mablag'laringiz",
  credit_line: "Kredit liniyasi",
  no: "Yo'q",
  for_route: "Yo'nalish uchun",
  to_result_search: "Qidiruv natijalari uchun",
  only_a_z_space: "Faqat lotin harflari va bo'sh joy",
  only_a_z: "Faqat lotin harflari",
  invalid_phone: "noto'g'ri telefon raqami",
  min_2_symbol: "Kamida 2 ta belgi",
  select_value_from_list: "Ro'yxatdan qiymatni tanlang",
  check_date: "Sanani tekshiring",
  incorrect_number: "Karta raqami noto‘g‘ri",
  incorrect_cvc: "Noto'g'ri kod",
  incorrect_card_holder: "Karta egasi nomi noto‘g‘ri",
  incorrect_card_month: "Noto'g'ri oy",
  incorrect_card_year: "Noto'g'ri yil",
  code: "Kod",
  message: "Xabar",
  close: "Yopish",
  error_message_part_1:
    "Nimadir noto‘g‘ri ketdi shekilli. Muammoning sabablarini tushunishimiz uchun texnik yordamga murojaat qiling.",
  error_message_part_2: "va sessiya raqamini kiriting",
  error_message_part_3: "Xabarni yopganingizdan so'ng siz xizmatning asosiy sahifasiga yo'naltirilasiz.",
  choose: "Tanlash",
  collapse: "Свернуть", //TODO: Find translation for that word
  looking_tickets: "Chiptalar qidirilmoqda",
  wait_please: "Iltimos kuting",
  we_issue_tickets: "Biz chiptalarni chiqaramiz, bu ko'p vaqt talab qilmaydi",
  created: "Yaratilgan",
  left: "Qoldi",
  less_than_minute: "bir daqiqadan kamroq",
  help: "Yordam",
  exchange_and_return_rules: "Almashtirish va qaytarish: qoidalar",
  after_flight: "Parvozdan keyin",
  go_to_section: "Bo'limga o'ting",
  next: "Keyinchalik",
  earlier: "Avvalroq",
  not_book_tickets: "Sizda bron qilingan reyslar yo'q",
  route_from: "Qayerdan",
  route_to: "Qayerga",
  date_from: "Borish",
  date_to: "Qaytish",
  find: "Topmoq",
  add_route: "Parvoz qo'shing",
  one_way: "Bir tomonga",
  two_way: "Borish va qaytish sayohati",
  multi_way: "Qiyin marshrut",
  economy: "Ekonom",
  business: "Biznes",
  first: "Birinchi",
  all_class: "Klassga bog'lamasdan",
  adt_main_label: "Voyaga etgan",
  inf_main_label: "Chaqaloq",
  chd_main_label: "Bola",
  adt_second_label: "12 yosh va undan katta",
  chd_second_label: "2 - 12 yosh",
  inf_second_label: "2 yilgacha (joysiz)",
  inf_with_seat_second_label: "joy bilan",
  passenger_1: "Bitta yo'lovchi",
  passenger_2: "Ikta yo'lovchi",
  passenger_3: "Uchta yo'lovchi",
  passenger_4: "To'rta yo'lovchi",
  passenger_5: "Beshta yo'lovchi",
  passenger_6: "Oltita yo'lovchi",
  agents: "Agentlarga",
  passenger_7: "Ettita yo'lovchi",
  passenger_8: "Sakkizta yo'lovchi",
  passenger_9: "To'qqizta yo'lovchi",
  only_there: "Borish",
  roundtrip: "Borish-qaytish",
  your_profile: "Sizning profilingiz",
  client_name: "Mijoz ismi",
  client_code: "Mijoz kodi",
  partner_code: "Hamkor kodi",
  partner_name: "Hamkor ismi",
  partner_currency: "Валюта партнера",
  current_balance: "Hamkor valyutasi",
  debt: "Joriy balans",
  credit_limit: "Kredit limiti",
  deposit: "Depozit",
  change_password: "Parolni o'zgartirish",
  old_password: "Joriy parolni kiriting",
  new_password: "Yangi parol",
  confirmation_password: "Parolni tasdiqlang",
  save: "Saqlash",
  passwords_must_match: "Parollar bir xil bo'lishi kerak",
  password_changed_successfully: "Parol muvaffaqiyatli almashtirildi",
  departure_title: "Jo'nash",
  there_title: "ketish",
  back_title: "Qaytish",
  validates_flights: "parvozlarni tasdiqlaydi",
  pay_for_your_reservation: "bron qilish uchun to'lash",
  hour_short: "s",
  day_short: "kun",
  minutes_short: "d",
  minutes_short_3: "daq",
  on_way: "Yo'lda",
  no_transfers: "Transferlar yo'q",
  class: "Klass",
  tariff_options: "Tarif variantlari",
  with_luggage: "Bagaj bilan",
  without_luggage: "Bagaj siz",
  no_timelimit: "To'lov muddati haqida ma'lumot yo'q",
  provider: "Provayder",
  price: "Narxi",
  legal_information: "Huquqiy ma'lumotlar",
  rules_of_use: "Foydalanish shartlari",
  privacy_policy: "Maxfiylik siyosati",
  payment: "To'lov",
  detail: "Batafsil ma'lumot",
  label_price_by_min: "Narxi (o'sish)",
  label_price_by_max: "Narx (pasayish)",
  transfers: "Transferlar",
  all_flights: "Barcha reyslar",
  without_transfers: "Transferlar yo'q",
  one_transfer: "1 transfer",
  many_transfer: "2 yoki undan ko'p transfer",
  not_found_recomm_first_text: "So‘rovingizga mos chiptalar topilmadi",
  not_found_recomm_second_text: "Boshqa sana yoki aeroport bilan qayta urinib ko'ring",
  not_found_recomm_by_filters: "Iltimos, filtr shartlarini o'zgartiring",
  flight_details: "Parvoz tafsilotlari",
  change_tariff: "Tarifni o'zgartirish",
  tariffs: "Tariflar",
  teh_stop: "Texnik to'xtash",
  transfer: "Transfer",
  local_time_message: "Ketish va kelish vaqtlari mahalliy hisoblanadi",
  route_tariffs_message: "Tarif butun yo'nalish uchun amal qiladi",
  airlines: "Aviakompaniyalar",
  airports: "Aeroportlar",
  departure: "Ketish",
  arrival: "Kelish",
  departure_time: "Ketish vaqti",
  arrival_time: "Kelish vaqti",
  flight_number: "Parvoz raqami",
  multiple_numbers_separated_by_spaces: "Bo'sh joy bilan ajratilgan bir nechta raqamlar",
  lowcost: "lowcost",
  transfer_1: "transfer",
  transfer_2_4: "transfers",
  transfer_5: "transferlar",
  free_seats_0: "joy yo'q",
  free_seats_1: "joy",
  free_seats_2_4: "joy",
  free_seats_5: "joy",
  one_adt: "Voyaga etgan",
  more_adt: "Katta",
  many_adt: "Katt",
  one_chd: "Ребенок",
  more_chd: "Bola",
  many_chd: "Bola",
  one_inf: "Chaqaloq",
  more_inf: "Chaqaloq",
  many_inf: "Chaqaloq",
  flight_one: "parvoz",
  flights_more: "parvoz",
  flights_many: "parvoz",
  found_one: "Topildi",
  found_more: "Topildi",
  flight_overview: "Parvoz haqida umumiy ma'lumot",
  to_top: "Yuqoriga",
  book: "Band qilish",
  buyer: "Xaridor",
  pnr: "Bronlash kodi / PNR",
  online_registration_locator: "Onlayn ro'yxatdan o'tish uchun lokator",
  route_information: "Yo'nalish haqida ma'lumot",
  order: "Buyurtma",
  flights_tickets: "Parvozga chiptalar",
  void_text: "Buyurtmani istalgan vaqtda bekor qilishingiz mumkin",
  void_order: "Buyurtmani bekor qilish",
  support_chat: "Qo'llab-quvvatlash bilan suhbatlashing",
  payment_method: "To'lov usuli",
  payment_with_balance: "Balansdan to'lov",
  payment_with_card: "Karta orqali to'lov",
  info_account: "Ma'lumot hisobi",
  get_tickets: "Chiptalarni chiqarish",
  left_before_the_time_limit: "Mavjud qolgan vaqt",
  expires: "Muddati tugaydi",
  payment_timed_out: "To'lov muddati tugadi",
  time_expired: "Vaqt tugadi",
  order_created: "Buyurtma yaratildi",
  return_ticket: "Chiptani qaytarish",
  exchange_ticket: "Chipta almashish",
  download_itinerary_receipt: "Yo'nalish kvitansiyasi yuklab olish",
  order_cost: "Buyurtma narxi",
  invoice_for_payment: "To'lov qaytnomasi",
  void_in_process: "Buyurtma bekor qilinmoqda",
  initial: "Buyurtma yaratildi",
  in_progress: "Chipta band qilindi",
  success: "Muvaffaqiyatli to'lov, chiptalar chiqarilgan",
  partially_success: "Qisman muvaffaqiyatli buyurtma",
  booking_fail: "Brony paytida xato",
  order_fail: "Buyurtma yaratishda xato",
  pay_fail: "To'lov paytida xatosi",
  order_cancel_from_client: "Buyurtma mijoz tomonidan bekor qilingan",
  order_cancel_from_admin: "Buyurtma administrator tomonidan bekor qilindi",
  order_cancel_by_job: "Vaqt chegarasi tufayli buyurtma bekor qilindi",
  order_cancel_error: "Buyurtmani bekor qilishda xato",
  application_refund: "Toʻlovni qaytarish soʻrovi yaratildi",
  partial_refund: "Buyurtma bo'yicha qisman qaytarish",
  order_cancel_by_gds: "Vaqt chegarasi tufayli buyurtma bekor qilindi",
  refund: "Buyurtma bo'yicha to'lovni to'liq qaytarish",
  ticketing_done: "Muvaffaqiyatli to'lov, chiptalar chiqarilgan",
  pay_success: "Muvaffaqiyatli to'lov, chiptalar rasmiylashtirilmoqda",
  booking_done: "Chipta band qilingan",
  exchange: "Chipta almashinuvi",
  void: "Buyurtma bekor qilindi",
  ticketing_fail: "Chiptalarni berishda xato",
  year_one: "yil",
  year_more: "yilning",
  years: "yillar",
  day_one: "kun",
  day_more: "kun",
  days: "kunlar",
  hour_one: "soat",
  hour_more: "soat",
  hours: "soat",
  minute_one: "daqiqa",
  minute_more: "daqiqa",
  minutes: "daqiqa",
  second_one: "soniya",
  second_more: "soniya",
  seconds: "soniya",
  passenger_list: "Yo'lovchilar ro'yxati",
  your_name: "Ismingiz",
  search_order: "Buyurtma qidirish",
  locator: "Lokator",
  cancel: "Bekor qilish",
  passenger_name: "Yo'lovchi familiyasi",
  do_not_find_order_message: "Buyurtma topilmadi. Iltimos, so'ralgan parametrlarni o'zgartiring",
  orders: "Buyurtmalar",
  contract_settings: "Shartnoma sozlamalari",
  main: "Asosiy",
  users: "Foydalanuvchilar",
  affiliated_companies: "Birlashgan kompaniyalar",
  all_orders_label: "Barcha buyurtmalar",
  book_done_label: "Band qilingan",
  pay_done_label: "To'langan",
  ticket_done_label: "Chiqarilgan",
  cancel_done_label: "Bekor qilingan",
  wait_refund_label: "Qaytish kutilmoqda",
  refund_done_label: "Qaytgan",
  partial_refund_done_label: "Qisman qaytarildi",
  void_done_label: "Bekor qilingan",
  error_order_label: "Buyurtma xatosi",
  date_create_from: "Yaratilgan sanadan boshlab",
  order_status_label: "Buyurtma holati",
  search_title: "Familiya, manzil yoki shaharni kiriting",
  date_create_to: "Yaratgan sanasi gacha",
  clear: "sozlamalarni tiklash",
  passenger_success_save: "Yo‘lovchi muvaffaqiyatli saqlandi",
  payment_with_dpt: "Balansdan to'lash",
  payment_with_unt: "'Uniteller' orqali to'lash",
  payment_with_spt: "'Milliy karta' orqali to'lash",
  include_your_fees: "to'lovingizni o'z ichiga oladi",
  before: "oldin",
  your_fees: "Sizning to'lovingiz",
  month_one: "oy",
  months_more: "oy",
  months: "oy",
  all_passengers: "Barcha yo'lovchilar",
  passenger_success_remove: "Yoʻlovchi muvaffaqiyatli oʻchirildi",
  companies: "Kompaniyalar",
  no_companies: "Kompaniyalar yo'q",
  no_matches_found: "Hech qanday moslik topilmadi",
  no_passengers: "Sizda hali saqlangan yoʻlovchilar yoʻq",
  enter_passenger_name: "Yo'lovchining ismini yoki familiyasini kiriting",
  add_passenger: "Yo'lovchi qo'shing",
  your_partner_fees: "Sizning to'lovingiz",
  type_of_fee: "To'plam turi",
  value: "Ma'nosi",
  percent: "Foiz",
  saved_successfully: "muvaffaqiyatli saqlangan",
  error_saving: "Saqlashda xatolik yuz berdi",
  percent_from_total: "Jami foiz",
  percent_from_fare: "Tarifning ulushi",
  fix_flat: "Ruxsat etilgan to'lov",
  fix_flat_and_percent_from_total: "Ruxsat etilgan to'lov va jami foiz",
  fix_flat_and_percent_from_fare: "Ruxsat etilgan to'lov va tarifning foizi",
  reset: "Qayta o'rnatish",
  invalid_value: "Noto'g'ri qiymat",
  no_orders_found: "Hech qanday buyurtma topilmadi",
  personal_data: "Shaxsiy ma'lumotlar",
  upload: "Yuklab olish",
  your_logo: "Sizning logotipingiz",
  image_uploaded_successfully: "Rasm muvaffaqiyatli yuklandi",
  upload_logo: "Logotip yuklash",
  image_too_large: "Rasm hajmi juda katta",
  image_resolution_too_high: "Rasm ruxsati juda yuqori",
  image_resolution_too_small: "Rasm ruxsati juda kichik",
  rule_for_upload_img_1: "Rasm formati .jpg, .jpeg, .png, .gif",
  rule_for_upload_img_2: "Ruxsat 300x100px dan 2000x2000px gacha.",
  rule_for_upload_img_3: "Fayl hajmi 4 MB dan oshmasligi kerak.",
  amount: "miqdor",
  rules_text_1: "Bo'limdagi quyidagi iboralarga e'tibor bering",
  rules_text_2: "chipta qaytarib berilmaydi",
  rules_text_3: "reys uchun ko'rsatilmagan taqdirda chipta qaytarilmaydi",
  rules_text_4: "Ayirboshlash qoidalari subtitrli bo'limda tasvirlangan ",
  line: "Chiziq",
  rules_text_5:
    "o'zgarishlarga yo'l qo'yilmasligini anglatadi, shuning uchun parvoz sanalarini o'zgartirish mumkin emas",
  passenger_data: "Yo'lovchi tafsilotlari",
  comment: "Izoh",
  contact_details: "Aloqa ma'lumotlari",
  contact_information: "Aloqa ma'lumotlari",
  remove: "Oʻchirish",
  add_document: "Hujjat qo'shing",
  return_avia_rules: "Qanday almashtirish yoki qaytarish kerak",
  booking_avia_rules: "Chiptaga qanday buyurtma berish mumkin",
  created_at: "yaratilgan sana",
  updated_at: "Yangilanish sanasi",
  phone: "Telefon",
  create_user: "Foydalanuvchi yarating",
  add: "Qo'shish",
  edit: "Tahrirlash",
  confirm: "Tasdiqlang",
  successfully_deleted: "Muvaffaqiyatli oʻchirib tashlandi",
  error_deleting: "Oʻchirishda xatolik yuz berdi",
  mess_want_to_delete: "Haqiqatan ham oʻchirib tashlamoqchimisiz",
  record_one: "Yozib olish",
  record_more: "yozuvlar",
  record_many: "yozuvlar",
  user_type: "Mijoz turi",
  admin_role: "Administrator",
  manager_role: "Menejer",
  user_agent_role: "Agent",
  user_role: "Foydalanuvchi",
  security_guarantees_unitaller: "Xavfsizlik kafolatlari",
  activation_title: "Foydalanuvchini faollashtirish",
  confirmation_activation_password: "Parolni tasdiqlang",
  enter_activation_password: "parolni kiriting",
  activation: "Faollashtirish",
  login: "Avtorizatsiya",
  error_activating_user: "Foydalanuvchini faollashtirishda xatolik yuz berdi",
  error: "Xatolik",
  to_login_form: "Kirish sahifasiga",
  expired_date: "Muddati tugagan",
  refund_button_title: "Qaytish",
  itinerary_receipt: "Yo'nalish kvitansiyasi",
  action: "Harakat",
  to_pay: "To'lash",
  order_cancel_error_mess: "Buyurtmani bekor qilishda xato",
  order_cancel_successfully_mess: "Muvaffaqiyatli bekor qilish",
  partner: "Hamkor",
  inn: "STIR",
  kpp: "nazorat punkti",
  logo: "Logotip",
  create_partner: "Hamkor yarating",
  status: "Holat",
  add_your_details: "Tafsilotlaringizni qo'shing",
  return_request_created: "Qaytish so'rovi yaratildi",
  error_creating_refund_request: "Qaytish so'rovini yaratishda xatolik yuz berdi",
  activation_status: "Faollashtirish holati",
  placeholder_date_picker: "kk.oo.yyyy",
  created_company_successfully: "Kompaniya yaratildi",
  error_created: "Yaratishda xatolik yuz berdi",
  deposit_statement: "Depozit orqali ko'chirma so'rash",
  transactions: "O'tkazmalar",
  weight_unit: "kg",
  credit_funds: "Kredit mablag'lari",
  overrun: "Ortiqcha xarajat",
  transaction_time: "Tranzaksiya vaqti",
  before_balance_change: "Balansni o'zgartirishdan oldin",
  allow_balance_payment: "Balansdan to'lashga ruxsat bering",
  approved_balance_request_successfully: "Balans hisoboti uchun ariza tasdiqlandi",
  error_approved_balance_request_successfully: "Balans hisoboti uchun arizani tasdiqlashda xato",
  balance_payment_request: "Balans hisoboti uchun ariza",
  balance_payment_approved: "Balans hisoboti uchun ariza tasdiqlandi",
  payment_with_uzc: "“Uzcard” orqali toʻlash",
  allow_payment: "To'lovga ruxsat bering",
  statement_request: "Ekstrakt uchun so'rov",
  client: "Mijoz",
  was_changed_to: "ga o'zgartirildi",
  deposit_statement_request_created: "Depozit bo'yicha depozit uchun ariza yaratildi",
  registration: "Roʻyxatdan oʻtish",
  error_creating_deposit_statement_request: "Depozit depoziti uchun ariza yaratishda xatolik yuz berdi",
  deposit_statement_request_confirmed: "Depozitni yechib olish uchun ariza tasdiqlandi",
  error_confirming_deposit_statement_request: "Depozit bo'yicha ko'chirma uchun arizani tasdiqlashda xato",
  fare: "Tarif",
  fees: "Yig'imlar",
  partner_fees: "Hamkor yig'imlari",
  taxes: "Soliqlar",
  ticket: "Chipta",
  pay_for_the_order: "Buyurtma uchun to'lov",
  operating_company: "Operatsion kompaniya",
  download_charter_certificate: "Charter sertifikat shartnomasini yuklab olish",
  certificate_issued: "Charter sertifikati berilgan",
  charter: "Charter",
  service: "Xizmat",
  income: "Kelmoq",
  spending: "Iste'mol",
  remainder: "Qoldiq",
  description: "Tavsif",
  contract: "Shartnoma",
  column_selection: "Ustunlarni tanlash",
  your_commission: "Sizning komissiyangiz",
  no_result: "Natija yoʻq",
  date: "sana",
  movement_balance: "Balansdagi mablag'larning harakati",
  current_balance_sheet: "Joriy balans holati",
  indebtedness: "Qarzdorlik",
  date_from_d: "Sanadan",
  date_to_d: "Sanagacha",
  order_num: "Buyurtma raqami",
  booking_num: "bron raqami",
  search_operations: "Operatsiya qidiruvi",
  upload_report: "Hisobot yuklab olish",
  payment_with_vsl: "“Milliy karta” bilan toʻlash",
  popular_destinations: "Mashhur yo'nalishlar",
  more_options: "Boshqa variantlar",
  hide_list: "Varaqni yashirish",
  straight: "To'g'ridan-to'g'ri",
  with_transfer: "Transferlar bilan",
  finances: "Moliya",
  contacts: "Kontaktlar",
  all_classes: "Barcha klasslar",
  difficult_routes: "Murakkab marshrutni yarating",
  search_history: "Qidiruv tarixi",
  go_to_page: "havolaga rioya qiling",
  find_tickets: "chiptalarni toping",
  tours_discount: "Foydali sayohatlar va chegirmalar",
  last_news: "So'ngi yangiliklar",
  more_details: "Batafsil",
  about_company: "Kompaniya haqida",
  wannatalk: "Tanishamizmi?",
  customer_rew: "Mijozlarning sharhlari",
  my_profile: "Mening profilim",
  contact_us: "Biz bilan bog'lanish",
  last_name: "Familiya",
  first_name: "Ism",
  father_name: "otanining ismi",
  enter_login: "Loginingizni kiriting",
  enter_name: "Ismingizni kiriting",
  enter_surname: "Familiyangizni kiriting",
  enter_fathname: "Ota ismingizni kiriting",
  aeroflot: "Aeroflot",
  uz_airways: "Uzbekiston Havo Yo'llari",
  turkish: "Turkiya Havo Yo'llari",
  ural: "Ural Havo Yo'llari",
  qanot: "Qanot Sharq",
  apgAS: "APG Air Solution",
  pobeda: "Pobeda",
  airastana: "Air Astana",
  flyaristan: "Fly Aristan",
  s7: "Sibiry xavo yo'llari",
  belavia: "Belavia",
  enter_password: "Parolingizni kiriting",
  flight_search: "Parvozlarni qidirish",
  buy_air_tickets: "Uydan chiqmasdan chipta sotib oling",
  log_in: "Kirish",
  return_to_simple_route: "Oddiy marshrutga qayting",
  delete: "Oʻchirish",
  max_num_flights: "Siz maksimal parvozlar sonini qo'shdingiz",
  select_dep_date: "Ketish sanasini tanlang",
  select_return_date: "Qaytish sanasini tanlang",
  select_class: "klassni tanlang",
  login_as_user: "Foydalanuvchi sifatida tizimga kiring",
  enter_recieved_code: "Olingan kodni kiriting",
  come_up_your_pass: "Parolingizni yarating",
  enter_valid_mail: "Yaroqli elektron pochta manzilini kiriting",
  must_field_characters: "Maydon kamida 6 ta belgidan iborat bo'lishi kerak",
  register_to_save_data: "Ma'lumotlaringizni saqlash uchun ro'yxatdan o'ting",
  ins_main_label: " Chaqaloq",
  ins_second_label: " 2 yilgacha (joy bilan)",
  newsletter_subscription: "Axborot byulleteniga obuna bo'ling",
  newsletter_subscription_text: "Barcha muhim yangiliklar va ajoyib takliflardan xabardor bo'lish uchun obuna bo'ling!",
  subscribe: "Obuna boʻling",
  successfully_subscribed: "Siz muvaffaqiyatli obuna bo'ldingiz!",
  subscription_error: "Obuna boʻlishda xatolik yuz berdi, keyinroq qayta urinib koʻring!",
  all_news: "Barcha yangiliklar",
  tel: "Tel",
  enter_email: "Elektron pochtangizni kiriting",
  menu: "Menyu",
  settings: " Sozlamalar",
  deposit_method: "To'ldirish usulini tanlang",
  recharging_amount: "To'ldirish miqdori",
  recharge: " To'ldirish",
  recharging_balance: "Balansni to'ldirish",
  done: " Tayyor",
  not_found_content: " Topilmadi",
  not_found_content_text: " Kechirasiz, bu kontent tanlangan tilda mavjud emas",
  airport_code: "Aeroport kodi",
  from_city: "Shahardan",
  to_city: "Shaharga",
  weekdays: "Hafta kunlari",
  weekends: "Dam olish kunlari",
  weekends1: "1 va 2-yanvar",
  weekends2: "8 va 21-mart",
  weekends3: "9-may",
  weekends4: "1-sentabr",
  weekends5: "1-oktabr",
  weekends6: "8 va 31-dekabr",
  weekends7: "shuningdek, Hayit bayramlarida",
  airline: "Aviakompaniya",
  min_number: "To'ldirish miqdori kattaroq bo'lishi kerak",
  only_numbers: " Faqat raqamlar",
  must_register: " Bizning veb-saytimizda chiptalarni sotib olish uchun siz tizimga kirishingiz kerak",
  daily: "Kunlik",
  fly_tagline: "Biz bilan uchish oson",
  sorting: "Tartiblash",
  i_read_fares: "bilan tanishib qoldim",
  i_accept_fares: "va men ular bilan roziman",
  prices_table: "Narxlar jadvali",
  continue: "Davom etish",
  reset_error_title: "Xato",
  reset_error_content: "Kechirasiz, so‘rovni bajarishda xatolik yuz berdi",
  reset_success_title: "Siz parolingizni muvaffaqiyatli o'zgartirdingiz!",
  page_my_transactions: "Tugallangan to'lovlar to'g'risida hisobot",
  reset_success_content: 'Yangi parol bilan tizimga kirish uchun "Kirish" tugmasini bosing',
  forgot_success_title: "Muvaffaqiyatli!",
  forgot_success_content: "E-pochtangizga parolni tiklash havolasi yuborildi.",
  forgot_error_title: "Xato",
  forgot_error_content: "Kechirasiz, lekin siz noto'g'ri elektron pochtani kiritdingiz",
  password_confirm: "Parolni tasdiqlash",
  password_confirm_error: "Parollar mos kelmayapti",
  enter_password_confirm: "Parolni tasdiqlang",
  reset_password: "Parolingizni qayta o'rnating",
  enter_new_password: "Yangi parolni kiriting",
  forgot_password: "Parolni unutdingizmi?",
  mail_sent: "Xat yuborildi!",
  password_recovery_text:
    "\n" +
    "Bizning web-saytimizda ro'yxatdan o'tgan elektron pochta manzilini kiriting. Sizga kirishni tiklash uchun" +
    " havola yuboramiz",
  password_recovery_confirm_text: "Sizga qayta tiklash havolasi bilan xat yubordik.",
  forgot_password_summary: "Parolingizni tiklash uchun elektron pochtangizni kiriting",
  popup_event_text: "Roʻyxatdan oʻtganingizdan soʻng shaxsiy hisobingiz balansiga 100 somoniy bonus oling",
  popup_description: "Saytda ro'yxatdan o'ting va uyingizdan chiqmasdan aviachiptalarni onlayn xarid qiling!",
  //payment: "'Korti milli' orqali to'lov",
  from_airport: "Aeroportdan",
  not_enough_balance: "To'lash uchun mablag' etarli emas",
  questions_and_answers: "Savol va Javob",
  payment_methods: "To'lov usullari",
  our_partners: "Bizning hamkorlarimiz",
  confirm_actions: "Saytdagi harakatingizni tasdiqlang",
  payer_contacts: "Xaridor kontaktlari",
  buyer_email: "Xaridor elektron pochtasi",
  enter_buyer_email: "Xaridorning elektron pochta manzilini kiriting",
  locator_order_number_or_booking_number: "Lokator, buyurtma raqami yoki bron raqami",
  enter_locator_order_number_or_booking_number: "Lokator, buyurtma raqami yoki bron raqamini kiriting",
  activate: "Faollashtirish",
  register: "Roʻyxatdan oʻtish",
  deals: "Aksiyalar",
  pre_cashback: "Aviachiptalarni sotib oling va belgilangan narxga ega bo'ling",
  partners: "Hamkor bo'lgan aviakompaniyalar",
  scoreborad: "Aeroportning onlayn ekrani",
  cashback0: "To'g'ridan-to'g'ri shartnoma. Aviakompaniya narxlari.",
  cashback_from:
    "Loyiha hamkori aviakompaniyalarining keshbeki Oʻzbekiston aeroportlaridan reyslarga nisbatan qoʻllaniladi. Cashback tarif asosida hisoblanadi.",
  cashback_from_in:
    "Loyiha hamkor aviakompaniyalarining keshbeki Oʻzbekiston aeroportlaridan / aeroportlariga parvozlar uchun qoʻllaniladi. Cashback tarif asosida hisoblanadi.",
  tarif: "tarifdan",
  post_cashback: "shaxsiy hisobingiz balansiga",
  cashback_message:
    "Keshbekni olish uchun siz veb-saytda ro'yxatdan o'tishingiz va bizning xizmatimiz orqali chiptalarni xarid qilishingiz kerak. ",
  cashback_message_auth: "Siz uchun quyidagi cashback takliflari mavjud:",
  page_home: "Chiptalarni kassaga bormasdan onlayn xarid qilish",
  page_faq: "Ommabop savollarga javoblar",
  page_login: "Shaxsiy hisobingizga kiring",
  page_registration: "Ro'yxatdan o'ting va aviachiptalarni sotib oling",
  page_results: "Qiziqish yo'nalishi bo'yicha natijalar",
  page_booking: "Muayyan chiptani bron qilish",
  page_order: "Tugallangan buyurtma haqida ma'lumot",
  page_orders_list: "Tugallangan buyurtmalar haqida ma'lumot",
  page_my_profile: "Shaxsiy ma'lumot",
  page_add_balance: "Shaxsiy hisobingiz balansini to'ldirish",
  page_popular_destinations: "Mashhur parvozlar jadvali",
  page_news: "Mashhur aviatsiya yangiliklari va Fly TJ",
  page_forgot_password: "Uyingizdan chiqmasdan aviachiptalarni onlayn xarid qiling!",
  page_reset_password: "Uyingizdan chiqmasdan aviachiptalarni onlayn xarid qiling!",
  useful_info: "Foydali ma'lumot",
  copy_link: "Havolani nusxalash",
  copy_order_link_text: "Bu buyurtmaga kirish huquqini baham koʻrish uchun havoladan nusxa oling",
  link_copied_to_clipboard: "Havola vaqtinchalik xotiraga nusxalandi",
  unsubscribe_success: "Siz yangiliklar byulleteniga obunani muvaffaqiyatli bekor qildingiz!",
  unsubscribe_error: "Qayta ishlash jarayonida xatolik yuz berdi",
  activation_code_send_to_email: "Tasdiqlash kodi elektron pochta manzilingizga yuboriladi.",
  choose_gender: "Jinsni tanlang",
  old_information: "Ma'lumotlar eskirgan!",
  refresh: "Yangilash",
  refresh_page:
    "Aviachiptalar narxi kuniga bir necha marta yangilanadi. Joriy narxlarni tekshirish uchun sahifani yangilang.",
  will_take_2_minutes: "Bu biroz vaqt olishi mumkin",
  error_additional_options_text:
    "Bandlov uchun qoʻshimcha imkoniyatlarni yoqishda xatolik yuz berdi. Qoʻshimcha parametrlarsiz davom ettirilsinmi?",
  edit_personal_data: "shaxsiy ma'lumotlaringizni o'zgartiring",
  minimum_amount: "Minimal miqdor",
  additional_options: "Qo'shimcha xizmatlar",
  additional_options_not_found: "Hech qanday qo'shimcha xizmatlar topilmadi!",
  profile_changed: "Profil muvaffaqiyatli oʻzgartirildi",
  welcome: "xush kelibsiz",
  register_on_somon_air: "Somon Air uchun ro'yxatdan o'tish",
  min_6_symbol: "Kamida 6 ta belgi",
  your_id: "Sizning ID",
  cheapest_without_baggage: "Bagajsiz eng arzon",
  cheapest_with_baggage: "Bagaj bilan eng arzon",
  most_convenient_transfes: "Eng qulay transfer",
  fly_ru_tagline: "Dunyoni his eting!",
  fly_ru_subtagline: "Dunyodagi barcha aviakompaniyalarga kirishingiz mumkin",
  fly_ru_round_the_clock: "24 soat",
  refund_order: "Ilovani qaytarish",
  refund_reason: "Qaytish sababi",
  order_payment_method: "Buyurtmani to'lash usuli",
  payer_user_id: "Buyurtmani to'lagan foydalanuvchining identifikatori",
  four_last_digits_of_card: "To'lov kartasining oxirgi 4 raqami",
  user_id: "Foydalanuvchi IDsi",
  buyer_phone: "Xaridorning telefon raqami",
  requisite_of_account_to_return: "To'lov qaytariladigan hisob ma'lumotlari",
  requisite_of_account: "Hisob tafsilotlari",
  passenger_name_and_surname: "Yo'lovchining familiyasi va ismi",
  passport_serial_and_number: "Seriya va pasport raqami",
  passport_copy: "Pasport nusxasi",
  send: "Yuborish",
  payment_from_client_balance: "Mijoz balansidan to'lov",
  payment_from_card: "Karta orqali to'lov",
  vasl_tagline: "Qulaylik birinchi o'rinda turadi!",
  technical_support: "Texnik yordam",
  success_refund_order_request: "Toʻlovni qaytarish arizasi muvaffaqiyatli yuborildi!",
  error_refund_order_request: "Pulni qaytarish uchun ariza yuborishda xatolik yuz berdi!",
  find_options: "chiptalarni toping",
  buy_tickets_to_all_destinations: "va barcha yo'nalishlarga aviachiptalarni sotib oling!",
  filters: "Filtrlar",
  night_flight: "Kechasi transfer",
  change_airport: "Aeroportni almashtirish",
  and: "va",
  from: "dan",
  refund_order_success: "Toʻlovni qaytarish uchun ariza muvaffaqiyatli topshirildi",
  refund_order_error: "Qaytish arizasini yuborishda xatolik yuz berdi",
  buy_tour: "Turnini sotib oling",
  payment_alert: "Hurmatli foydalanuvchi, buyurtmangiz toʻlandi. Bo'shatish vaqti - 1 minut.",
  customer_office_address: "Mijozlar uchun ofis manzili",
  inner_document: "Ichki hujjat",
  document_copy: "Hujjatning nusxasi",
  maximum_amount: "Maksimal miqdor",
  search_limits_exceeded:
    "Hurmatli foydalanuvchi, siz qidiruvlar sonidan oshib ketdingiz, ishingizni davom ettirish uchun tizimga kiring/roʻyxatdan oʻting",
  flight_without_seats: "Afsuski, bu reys uchun o'rindiqlar mavjud emas.",
  document_type: "Hujjat turi",
  document_number: "Hujjat raqami",
  expiration_date: "Amal qilish muddati",
  any_cabin: "Har qanday klass",
  my: "Mening",
  site: "Saytim",
  show_more: "Ko'proq ko'rsatish",
};

export default uz;

import styled from "styled-components";

export const TableContainer = styled("div")(({ theme }) => ({
  whiteSpace: "nowrap",
  overflowX: "auto",
}));

export const Table = styled("table")(({ theme, minWidth, isClickableRow }) => ({
  borderCollapse: "separate",
  borderSpacing: "0 3px",
  overflow: "hidden",
  width: "100%",
  maxWidth: "100%",
  minWidth: minWidth ?? "auto",
  position: "relative",

  "& thead > tr": {
    backgroundColor: [theme.palette.footerBg],
  },

  "& th": {
    padding: "20px 10px",
    fontWeight: "normal",
    color: "#fff",
    textAlign: "left",

    "@media (max-width: 460px)": {
      padding: "10px 5px",
      fontSize: 14,
    },
  },

  "& tbody > tr": {
    marginTop: 2,
    backgroundColor: "#fff",
    
    ...(isClickableRow ? ({
      "&:hover": {
        backgroundColor: "#f3f3f3",
        cursor: "pointer",
      }
    }) : {}),
  },

  "& td": {
    padding: 10,

    "@media (max-width: 460px)": {
      padding: "5px",
      fontSize: 14,
    },
  }
}));

export const PaginationBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  gap: 10,
  marginTop: 20,
}));

export const PaginationButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  backgroundColor: "#E63946",
  color: "#fff",
  border: "none",
  padding: 0,
  width: 30,
  height: 30,
  borderRadius: 5,
  fontSize: 24,

	transition: "all .3s ease-out",

	"&:hover": {
		backgroundColor: [theme.palette.hoverButtonBg],
	},

  "&:disabled": {
    backgroundColor: "#b7b7b7",
  },
}));

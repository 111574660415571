import i18next from "i18next";
import { useState } from "react";
import { PartnersSwiperComponent } from "../info/PartnersComponent/PartnersSwiperComponent/PartnersSwiperComponent";
import { ContainerComponent, SelectComponent } from "../ui";
import { Title, Wrapper } from "./Home.style";

const Home = () => {
  const [airport, setAirport] = useState("9600397");
  const { t } = i18next;

  return (
    <ContainerComponent fixed style={{ width: "100%" }}>
      <Wrapper>
        <Title>{t("scoreborad")}</Title>
        <SelectComponent
          value={airport}
          options={[
            { value: "9600397", label: "Ташкент" },
            { value: "9623553", label: "Самарканд" },
            { value: "9623554", label: "Бухара" },
            { value: "9623644", label: "Ургенч" },
            { value: "9623645", label: "Термез" },
            { value: "9626890", label: "Навои" },
          ]}
          onSelect={value => setAirport(value)}
        />
        <iframe
          title="table"
          frameborder="0"
          style={{
            "-moz-box-shadow": "0 2px 3px rgba(0, 0, 0, 0.5)",
            "-webkit-box-shadow": "0 2px 3px rgba(0, 0, 0, 0.5)",
            "box-shadow": "0 2px 3px rgba(0, 0, 0, 0.5)",
            overflow: "hidden",
            border: 0,
            width: "100%",
            height: 642,
          }}
          src={`https://rasp.yandex.ru/informers/station/${airport}/?size=15&amp;color=1&amp;type=tablo`}
        />
        <PartnersSwiperComponent />
      </Wrapper>
    </ContainerComponent>
  );
};

export default Home;

import { useNavigate, useParams } from "react-router-dom";
import { data } from "../data";
import { Content, Description, Image, Wrapper } from "./style";

export const PartnersInfoComponent = () => {
  const { airline } = useParams();
  const airlineInfo = data.find(item => item.name === airline);
  const navigate = useNavigate();

  if (!airlineInfo) {
    navigate("/");
  }

  return (
    <Wrapper>
      <Content>
        <Image>
          <img src={airlineInfo.img_url} alt={airlineInfo.name} width={300} height={70} />
        </Image>
        <Description>
          {airlineInfo.description?.map((item, index) => (
            <div key={index} style={{ marginBottom: "1rem", lineHeight: "28px" }}>
              <div dangerouslySetInnerHTML={{ __html: item }}></div>
            </div>
          ))}
        </Description>
      </Content>
    </Wrapper>
  );
};

import { useEffect, useRef, useState } from "react";

import { AiOutlineRollback } from "react-icons/ai";
import { PiHandbagSimple, PiSuitcaseRolling } from "react-icons/pi";
import { RiRepeatFill } from "react-icons/ri";

import { LoadingBlock, TariffItem, TariffItemButton, TariffItemClass, TariffItemDetails, TariffItemIcons, TariffItemTitle, Wrapper } from "./style";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBrandFares, prebook } from "../../../protocols";
import { setBrandFareRecId, setBrandFares, setBrandRules } from "../../../store/resultDataSlice";
import classNames from "classnames";
import { getServiceClass, normalizePrice } from "../../../utils";
import { DotsLoadingComponent } from "../../ui";
import { RulesModalComponent } from "../../rulesModal";

const RecommendationTarrifsComponent = ({ recId, brandRecId, brandName }) => {
  const dispatch = useDispatch();

  const { brandFares, session } = useSelector(state => state.resultData);
  const { flightType } = useSelector(state => state.searchParams);

  const [isLoading, setIsLoading] = useState(true);

  const brandFaresItem = brandFares[recId] || [];

  const getBrandFare = useRef(() => {
    if (brandFaresItem.length === 0 || !brandFares[recId]) {
      const requestParams = {
        session_id: session,
        flight_type: flightType,
        rec_id: recId,
      };

      getBrandFares(requestParams).then(response => {
        dispatch(setBrandFares({ recId, flights: response.flights }));
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    getBrandFare.current();
  }, []);

  const handleSetTariff = value => {
    dispatch(setBrandFareRecId({ recId, brandRecId: value }));
  };

  return (
    <Wrapper $isLoading={isLoading}>
      {isLoading ? (
        <LoadingBlock>
          <DotsLoadingComponent color="#01A0D4" />
        </LoadingBlock>
      ) : brandFaresItem.map(tariff => (
        <TarriffItem
          key={tariff.rec_id}
          tariff={tariff}
          brandRecId={brandRecId}
          brandName={brandName}
          onSetTariff={handleSetTariff}
        />
      ))}
    </Wrapper>
  );
};

const TarriffItem = ({ tariff, brandRecId, brandName, onSetTariff }) => {
  const { brand_name, total_price, rec_id: recId, routes } = tariff;

  const { t } = i18next;
  const dispatch = useDispatch();

  const { currency } = useSelector(state => state.appConfigs);
  const { brandRules, session } = useSelector(state => state.resultData);

  const [isLoadingRules, setIsLoadingRules] = useState(false);
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);

  let isActive = false;

  if (brandRecId) {
    isActive = recId === brandRecId;
  } else if (brandName) {
    isActive = brand_name === brandName;
  }

  const departureSegment = routes[0].segments[0];
  const {
    is_refund,
    is_change,
    baggage,
    hand_luggage,
    service_class,
    baggage_weight,
    hand_luggage_weight,
  } = departureSegment;

  const handleOpenRulesModal = () => {
    if (brandRules.length === 0 || !brandRules[recId]) {
      setIsLoadingRules(true);

      prebook({ rec_id: recId, session_id: session }).then(response => {
        dispatch(
          setBrandRules({
            data: response,
            recId: recId,
          })
        );

        setIsRulesModalOpen(true);
        setIsLoadingRules(false);
      });
    } else {
      setIsRulesModalOpen(true);
    }
  };

  const brandRule = brandRules[recId];

  return (
    <TariffItem className={classNames({ active: isActive })} onClick={() => onSetTariff(recId)}>
      <TariffItemTitle>{normalizePrice(total_price[currency])} {currency}</TariffItemTitle>
      <TariffItemIcons>
        <div
          className={classNames({ notActive: !hand_luggage })}
          title={hand_luggage ? t("yes_hand_luggage") : t("no_hand_luggage")}
        >
          <PiHandbagSimple />
          {hand_luggage_weight ? <div className="hand-luggage-weight">{hand_luggage_weight}</div> : null}
        </div>

        <div className={classNames({ notActive: !baggage })} title={baggage ? t("yes_baggage") : t("no_baggage")}>
          <PiSuitcaseRolling />
          {baggage_weight ? <div className="baggage-weight">{baggage_weight}</div> : null}
        </div>

        <div className={classNames({ notActive: !is_change })} title={is_change ? t("yes_change") : t("no_change")}>
          <RiRepeatFill />
        </div>

        <div className={classNames({ notActive: !is_refund })} title={is_refund ? t("yes_refund") : t("no_refund")}>
          <AiOutlineRollback />
        </div>
      </TariffItemIcons>

      {/*<TariffItemDetails onClick={handleOpenRulesModal} $isLoading={isLoadingRules}>
        {isLoadingRules ? <DotsLoadingComponent color="#01A0D4" /> : t("tariff_rules")}
      </TariffItemDetails>*/}

      {brand_name && (
        <TariffItemTitle>{brand_name}</TariffItemTitle>
      )}

      <TariffItemClass>{getServiceClass(service_class)}</TariffItemClass>

      {brandRule && (        
        <RulesModalComponent
          openModal={!isLoadingRules && isRulesModalOpen}
          handleCloseModal={() => setIsRulesModalOpen(false)}
          fareRules={brandRule?.fare_rules}
          routes={brandRule?.routes}
          included={brandRule?.included}
        />
      )}
    </TariffItem>
  );
};

export default RecommendationTarrifsComponent;

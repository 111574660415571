const metaDescriptions = {
  ru: {
    homePage: "Добро пожаловать на сайт BiletBor.me: это лучшее место для бронирования авиабилетов без лишних хлопот. Изучите нашу удобную платформу для планирования путешествий, получения выгодных предложений и индивидуальных рекомендаций. Присоединяйтесь к миллионам довольных путешественников и начните свое следующее приключение с BiletBor.me уже сегодня!",
  },
  en: {
    homePage: "Welcome to BiletBor.me: Your premier destination for hassle-free flight bookings. Explore our user-friendly platform for seamless travel planning, unbeatable deals, and personalized recommendations. Join millions of satisfied travelers and start your next adventure with BiletBor.me today!",
  },
  uz: {
    homePage: "BiletBor.me veb-saytiga xush kelibsiz: bu hech qanday muammosiz aviachiptalarni bron qilish uchun eng yaxshi joy. Sayohatni rejalashtirish, ajoyib takliflar va moslashtirilgan tavsiyalar uchun foydalanish uchun qulay platformamiz bilan tanishing. Millionlab mamnun sayohatchilar qatoriga qo'shiling va bugun BiletBor.me bilan keyingi sarguzashtingizni boshlang!",
  },
};

export default metaDescriptions;

import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';

import i18next from "i18next";
import {Content, Info, List, MapLocation, Subtitles, Title, Wrapper, WrapperInner} from "./style";


export const ContactsComponent = () => {
	const {t} = i18next;

	return (
		<Wrapper>
			<Title>
				{t("contact_information")}
			</Title>
			<WrapperInner>
				<Info>
					<div>
						<Subtitles>
							{t("phone_number")}
						</Subtitles>
						<Content>
							{t("contact_number")}
						</Content>
					</div>

					<div>
						<Subtitles>
							{t("email")}
						</Subtitles>
						<Content>
							<a href="mailto:biletbor.me@gmail.com">biletbor.me@gmail.com</a>
						</Content>
					</div>

					<div>
						<Subtitles>
							{t("workH")}
						</Subtitles>
						<Content>
							Biz har kuni soat 09:00 dan 23:00 gacha aloqadamiz.
						</Content>
					</div>

					<div>
						<Subtitles>
							{t("weekends")}
						</Subtitles>
						<List>
							<li>{t("weekends1")}</li>
							<li>{t("weekends2")}</li>
							<li>{t("weekends3")}</li>
							<li>{t("weekends4")}</li>
							<li>{t("weekends5")}</li>
							<li>{t("weekends6")}</li>
							<li>{t("weekends7")}</li>
						</List>
					</div>
				</Info>

				<MapLocation>
					<Subtitles>
						{t("location")}
					</Subtitles>
					<Content style={{marginBottom: "20px"}}>
						140100, Узбекистан, Самарканд,
						ул. Абдурахмана Жомий, 35
					</Content>

					<YMaps
							preload={true}
							query={{
								lang: 'ru_RU',
							}}
					>
						<Map
								width={"350px"}
								height={"350px"}
								defaultState={{
									center: [39.656418, 66.953953],
									zoom: 15 ,
									controls: ["zoomControl", "fullscreenControl"],
								}}
								modules={["control.ZoomControl", "control.FullscreenControl"]}
						>
							<Placemark
									defaultGeometry={[39.656418, 66.953953]}
									properties={{
										balloonContentBody:
												"This is balloon loaded by the Yandex.Maps API module system"
									}}
							/>
						</Map>
					</YMaps>
				</MapLocation>
			</WrapperInner>
		</Wrapper>
	)
};


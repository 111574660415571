import styled from "styled-components";

export const Wrapper = styled("div")((theme) => ({
	width: "100%",
	maxWidth: "1220px",
	margin: "0 auto",
	height: "auto",
	padding: "20px 20px 40px",
}));

export const WrapperInner = styled("div")(({theme}) => ({
	display: "grid",
	gridTemplateColumns: "repeat(2, 1fr)",
	justifyContent: "center",

	[theme.down('sm')]: {
		gridTemplateColumns: "1fr",
	},
}));

export const Info = styled("div")((theme) => ({
	display: "flex",
	flexDirection: "column",
	gap: "20px",
	marginTop: "20px",
}));

export const Title = styled("h1")((theme) => ({
	// display: "block",
	textAlign: "center",
	fontSize: "25px",
	fontWeight: "bold",
}));

export const Subtitles = styled("h5")((theme) => ({
	fontSize: "17px",
	fontWeight: "bold",
	marginBottom: "10px",
}));

export const Content = styled("p")((theme) => ({
	fontSize: "16px",
	fontWeight: "normal",
	lineHeight: "1.5",

	"& a": {
		color: "#01A0D4",
		textDecoration: "underline",
		textUnderlineOffset: "4px",
		transition: "all .3s ease-out",

		":hover": {
			color: "#01A0D490",
		}
	},
}));

export const List = styled("ul")((theme) => ({
	listStylePosition: "inside",
	listStyleType: "disc",
}));

export const MapLocation = styled("div")((theme) => ({
	marginTop: "20px",
}));
const titles = {
  ru: {
    homePage: "Главная страница",
    balancePage: "Ваш баланс",
  },
  en: {
    homePage: "Home page",
    balancePage: "Your balance",
  },
  uz: {
    homePage: "Главная страница",
    balancePage: "Sizning balansingiz",
  },
};

export default titles;

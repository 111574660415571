import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: "#dee3e6",
  flexGrow: 1,
  paddingBottom: 50,

  "& .orders-container": {
    whiteSpace: "nowrap",
    overflowX: "auto",
  }
}));

export const TitleComponent = styled("div")(({ theme }) => ({
  ...theme.paddings(15, 0),
  fontSize: 32,
  fontWeight: 600,
}));

export const NotFoundComponent = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  minHeight: 128,
  textAlign: "center",
  fontSize: 20,
}));

export const OrdersBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  flexGrow: 1,
  gap: 3,
  paddingTop: 20,
  minWidth: 1370,

  [theme.down("md")]: {
    width: "100%",
  },
}));

export const OrdersHeader = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 2fr 1fr 2fr 1fr 2fr 1fr 1fr",
  gap: 10,
  padding: "20px 10px",
  backgroundColor: "#01a0d4",
  color: "#fff",
}));

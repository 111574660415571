import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 2fr 1fr 2fr 1fr 2fr 1fr 1fr",
  gap: 10,
  ...theme.paddings(15),
  borderRadius: 4,
  backgroundColor: "#fff",

  "& > div": {
    ...theme.mixins.flexColumn,
    justifyContent: "center",
  }
}));

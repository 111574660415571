import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  height: "auto",
  width: "100%",
  maxWidth: 1500,
  margin: "0 auto",
  padding: "0 10px",

  [theme.down("xl")]: {
    maxWidth: 1200,
  },

  [theme.down("lg")]: {
    maxWidth: 1000,
  },

  [theme.down("md")]: {
    maxWidth: 800,
  },

  [theme.down("sm")]: {
    maxWidth: 600,
  },

  [theme.down("xs")]: {
    width: "100%",
    padding: "0px 20px",
  },
}));

export const Title = styled("h1")(({ theme }) => ({
  fontSize: 28,
  marginBottom: 20,
}));

export const Cards = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5,1fr)",
  gap: 30,
  padding: "20px 0",

  [theme.down("xl")]: {
    gridTemplateColumns: "repeat(3,1fr)",
  },
}));

import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "1530px",
  height: "100%",
  margin: "20px auto 20px",
  padding: " 0 15px",

  [theme.upDown("lg", "xl")]: {
    maxWidth: "1230px",
  },

  [theme.upDown("sm", "md")]: {
    maxWidth: "830px",
  },

  [theme.upDown("md", "lg")]: {
    maxWidth: "1040px",
  },

  "& .swiper_container": {
    width: "100%",
    height: "100%",
    margin: "20px 0",
    padding: "10px 20px",

    "& .swiperSlide": {
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

export const Content = styled("div")(({ theme }) => ({
  margin: "0 auto",
}));

export const Image = styled("p")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 400,
  height: 200,
  float: "right",
  margin: "10px 5px",
}));

export const Description = styled("div")(({ theme }) => ({
  position: "relative",
}));

import { LogoLink, Wrapper } from "./style";

const HeaderLogoComponent = () => {
	return (
		<Wrapper>
			<LogoLink href="/">
				<img src="/images/logo.png" alt="logo" style={{width: "200px", height: "auto"}} />
			</LogoLink>
		</Wrapper>
	)
};

export default HeaderLogoComponent;

import React from "react";

import i18next from "i18next";
import { CardDesc, Img, Title, Wrapper } from "./style";

export default function Card({ imgSrc, title, cashback, desc }) {
  const { t } = i18next;
  return (
    <Wrapper>
      <Img>
        <img src={imgSrc} alt="logo" />
      </Img>

      <CardDesc>
        <Title>
          <h2>{t(`${title}`)}</h2>
        </Title>
      </CardDesc>
    </Wrapper>
  );
}

import { useState } from "react";

import i18next from "i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { ContainerComponent, DotsLoadingComponent, TableComponent, TableFilters } from "../../ui";

import { LoadingBlock } from "../../../pages/ResultPage/style";

import { NotFoundComponent, OrdersBlock, OrdersHeader, TitleComponent, Wrapper } from "./style";
import OrderItemComponent from "../OrderItemComponent";
import { formatDateTime } from "../../../config";
import { getBookStatus, normalizePrice } from "../../../utils";

const OrdersComponent = ({ isLoading, orders, included, page, total, request }) => {
  const { language, t } = i18next;
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(page || 1);

  const CreatedTime = ({ createdAt }) => {
    const time = moment(createdAt, formatDateTime);

    return (
      <>
        <div>{time.format("DD.MM.YYYY")}</div>
        <div>{time.format("HH:mm:ss")}</div>
      </>
    );
  };

  const Routes = ({ routes }) => routes.map(route => {
    const departureAirportCode = route?.departure_airport_code;
    const arrivalAirportCode = route?.arrival_airport_code;
    const departureAirportCityCode = included.airport[departureAirportCode].city_code;
    const arrivalAirportCityCode = included.airport[arrivalAirportCode].city_code;
    const departureCity = included.city[departureAirportCityCode][language];
    const arrivalCity = included.city[arrivalAirportCityCode][language];

    return (
      <div key={route.id}>{`${departureCity} → ${arrivalCity}`}</div>
    );
  });

  const DepartureTime = ({ routes }) => {
    const route = routes?.length ? routes[0] : {};
    const time = moment(route?.departure_at, formatDateTime);

    return (
      <>
        <div>{time.format("DD.MM.YYYY")}</div>
        <div>{time.format("HH:mm")}</div>
      </>
    );
  };

  const Timelimit = ({ timelimit }) => {
    const timelimitUtc = moment.utc(timelimit, formatDateTime).tz(moment.tz.guess());

    return (
      <>
        <div>{timelimitUtc.format("DD.MM.YYYY")}</div>
        <div>{timelimitUtc.format("HH:mm:ss")}</div>
      </>
    );
  };

  return (
    <Wrapper>
      <ContainerComponent>
        <TitleComponent>{t("orders")}</TitleComponent>

        {isLoading && (
          <LoadingBlock>
            <DotsLoadingComponent />
          </LoadingBlock>
        )}
        
        {(!isLoading && !orders?.length) && (
          <NotFoundComponent>У вас нет заказов!</NotFoundComponent>
        )}

        {/* {(!isLoading && orders?.length) && (
          <TableFilters
            filters={[
              
            ]}
          />
        )} */}
      </ContainerComponent>

      <ContainerComponent fixed={false}>
        {(!isLoading && orders?.length) ? (
          <div className="orders-container">
            <TableComponent
              columns={[
                { key: "created_at", label: t("date"), render: (column) => <CreatedTime createdAt={column.created_at} /> },
                { key: "order_id", label: t("order_num") },
                { key: "booking_number", label: "PNR" },
                { key: "routes", label: t("route"), render: (column) => <Routes routes={column.routes} /> },
                { key: "departure_time", label: t("departure_time"), render: (column) => <DepartureTime routes={column.routes} /> },
                { key: "price", label: t("order_cost"), render: (column) => `${normalizePrice(column?.price)} ${column?.currency}` },
                { key: "timelimit", label: t("pay_before"), render: (column) => <Timelimit timelimit={column.timelimit} /> },
                { key: "booking_status", label: t("order_status_label"), render: (column) => getBookStatus(column?.booking_status, column?.order_status, t) },
              ]}
              data={orders}
              minWidth={1250}
              isClickableRow
              onClickRow={(column) => navigate(`/order/${column?.order_id}/${column?.session_id}`)}
            />
            {/* <OrdersBlock>
              <OrdersHeader>
                <div>{t("date")}</div>
                <div>{t("order_num")}</div>
                <div>PNR</div>
                <div>{t("route")}</div>
                <div>{t("departure_time")}</div>
                <div>{t("order_cost")}</div>
                <div>{t("pay_before")}</div>
                <div>{t("order_status_label")}</div>
              </OrdersHeader>

              {orders.map(order => (
                <OrderItemComponent
                  key={order.id}
                  data={order}
                  included={included}
                />
              ))}
            </OrdersBlock> */}
          </div>
        ) : null}
      </ContainerComponent>
    </Wrapper>
  );
};

export default OrdersComponent;

import { useEffect, useState } from "react";

import { useFormik } from "formik";
import i18next from "i18next";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { searchOrder } from "../../../protocols";

import { useToggle } from "../../../hooks";

import { DotsLoadingComponent, InputComponent, ModalComponent } from "../../ui";

import {
	IconButton,
	NotFoundText,
	SearchButton,
	SearchButtonBlock,
	SearchInputBlock,
	Wrapper,
} from "./style";

const HeaderSearchOrderComponent = () => {
  const { language, t } = i18next;
  const navigate = useNavigate();

  const {
    isTrue,
    set: { on, off },
  } = useToggle();

  const [isLoading, setIsLoading] = useState(false);
  const [notFindOrder, setNotFindOrder] = useState(false);

  const handleSearch = values => {
    setIsLoading(true);

    searchOrder({ ...values, language }).then(response => {
      const { data } = response.data;

      if (data) {
        navigate(`/order/${data.order_id}/${data.session_id}`);
        off();
      } else {
        setNotFindOrder(true);
      }

      setIsLoading(false);
    });
  };

  const validationSchema = Yup.object().shape({
    payer_email: Yup.string().trim().email(t("enter_valid_mail")).required(t("required")),
    search_term: Yup.string().trim().required(t("required")),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
    initialValues: {
      payer_email: "",
      search_term: "",
    },
    validationSchema,
    onSubmit: handleSearch,
    validateOnBlur: true,
  });

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.key === "Enter" || e.keyCode === 13) {
        handleSubmit();
      }
    };

    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleSubmit]);

  const handleClose = () => {
    off();
    setNotFindOrder(false);
    resetForm();
  };

  return (
    <div>
      <Wrapper>
        <IconButton onClick={on}>
          <IoSearchOutline />
        </IconButton>
      </Wrapper>

      {isTrue && (
        <ModalComponent active={isTrue} onClose={handleClose} title="Поиск заказа" maxWidth={480} fixedWidth>
          <Wrapper>
            <SearchInputBlock>
              <InputComponent
                value={values.payer_email}
                onChange={handleChange("payer_email")}
                type="email"
                required
                placeholder={`${t("enter_buyer_email")} ...`}
                onBlur={handleBlur("payer_email")}
                touched={touched.payer_email}
                error={errors.payer_email}
                label={t("buyer_email")}
              />

              <InputComponent
                value={values.search_term}
                onChange={handleChange("search_term")}
                type="email"
                required
                placeholder={`${t("enter_locator_order_number_or_booking_number")} ...`}
                onBlur={handleBlur("search_term")}
                touched={touched.search_term}
                error={errors.search_term}
                label={t("locator_order_number_or_booking_number")}
              />
            </SearchInputBlock>

            {notFindOrder && <NotFoundText>{t("do_not_find_order_message")}</NotFoundText>}

            <SearchButtonBlock>
              <SearchButton onClick={handleSubmit}>
                {t("find")}
                {isLoading ? (
                  <div>
                    <DotsLoadingComponent color="#fff" />
                  </div>
                ) : null}
              </SearchButton>
            </SearchButtonBlock>
          </Wrapper>
        </ModalComponent>
      )}
    </div>
  );
};

export default HeaderSearchOrderComponent;

const metaTitles = {
  ru: {
    homePage: "Онлайн-касса BiletBor.me",
  },
  en: {
    homePage: "Online ticketing BiletBor.me",
  },
  uz: {
    homePage: "Onlayn kassa BiletBor.me",
  },
};

export default metaTitles;

import { useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setShowSearch } from "../../store/appConfigsSlice";

import { ForgotPasswordForm } from '../../components';

const ForgotPasswordPage = () => {
	const dispatch = useDispatch();
	
	useEffect(() => {
    dispatch(setShowSearch(false));
  }, []);

	return (
		<ForgotPasswordForm />
	);
};

export default withTranslation()(ForgotPasswordPage);
